<template>
  <div ref="chartContainer">

    <template v-if="widget.chart === 'total_people'">
      <p class="display-3 font-weight-bold text-center">
        {{ densityData.objects_count || 0 }}
      </p>
      <p class="d-block text-center h4 mt-2">People count</p>
    </template>

    <template v-if="widget.chart === 'avg_stay'">
      <p class="display-3 font-weight-bold text-center">
        {{ avgTimePresent }}
      </p>
      <p class="d-block text-center h4 mt-2">
        Average time of stay (seconds)
      </p>
    </template>
  </div>
</template>

<script>
import axios from "@axios";
import moment from "moment";

export default {
  name: "PeopleDensityCount",
  props: {
    widget: {
      type: Object,
    },
  },
  data() {
    return {
      intervalId: null,
      densityData: {},
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      if (this.$refs.chartContainer.checkVisibility()) this.updateDensityData();
    }, 2 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    avgTimePresent() {
      if (!this.densityData) return 0;
      return moment.duration(this.densityData.avg_time_present, 'seconds').seconds();
    }
  },
  methods: {
    fetchDensityData() {
      return axios.get("services/density-data");
    },
    updateDensityData() {
      this.fetchDensityData().then(({data}) => {
        this.densityData = data.data;
      });
    }
  }
}
</script>

<style scoped>

</style>