<template>
  <div class="chart-main-container">
    <div class="chart-container">
      <!-- Debug Section -->
      <!-- <div class="debug-section">
        <h5>Debug Information</h5>
        <div class="debug-content">
          <pre>Widget Heatmap Data: {{ JSON.stringify(widget, null, 2) }}</pre>
          <pre>
          Current Filters: {{ JSON.stringify(currentFilters, null, 2) }}</pre
          >
          <pre>
ShowOptionsPanel: {{ JSON.stringify(showOptionsPanel, null, 2) }}</pre
          >
          <pre>
HeatMapInitialFilters: {{ JSON.stringify(heatMapInitialFilters, null, 2) }}</pre
          >
        </div>
      </div> -->

      <!-- Share and Filters Buttons -->
      <div class="d-flex icons-share-filters">
        <div class="icons-group">
          <!-- <template v-if="shouldShowClearBtn()">
            <a href="#" @click.prevent="removeFilters" class="text-danger mr-4">
              <icon-component icon="clear-filters" size="20" />
            </a>
          </template> -->
          <template v-if="topBarVisible">
            <a href="#" @click.prevent="shareData" class="ml-1 mr-1">
              <icon-component icon="share-icon" size="20" />
            </a>
          </template>
        </div>
      </div>

      <!-- Heatmap Container -->
      <div class="heatmap-container" ref="heatmapContainer">
        <div v-if="loading" class="loading-indicator">
          {{ $t("app.loading") }}...
        </div>
        <img
          :src="imageSrc"
          ref="heatmapImage"
          @load="onImageLoad"
          alt="Heatmap Image"
          class="heatmap-image"
          v-show="!loading"
        />
        <div
          ref="heatmapOverlay"
          class="heatmap-overlay"
          v-show="!loading"
        ></div>
      </div>
    </div>

    <!-- Options Panel -->
    <options-panel-image-heat-map
      :show.sync="showOptionsPanel"
      :widget="widget"
      :fields="fields"
      :modelOptions="modelOptions"
      :filters.sync="filters"
      :selected-chart-value.sync="selectedChartValue"
      :keep-filters="keepFilters"
      @apply-filters="applyFilters"
    />
  </div>
</template>

<script>
import axios from "@axios";
import h337 from "heatmap.js";
import IconComponent from "../icons/icon-component.vue";
import OptionsPanelImageHeatMap from "./OptionsPanelImageHeatMap.vue";

export default {
  name: "HeatMap",
  components: {
    IconComponent,
    OptionsPanelImageHeatMap,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    showOptionsPanel: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    modelOptions: {
      type: Object,
      default: () => ({}),
    },
    selectedChartValue: {
      type: Object,
      default: () => ({}),
    },
    keepFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageSrc: "",
      events: [],
      heatmapInstance: null,
      imageLoaded: false,
      resolutionWidth: 1920,
      resolutionHeight: 1080,
      resizeObserver: null,
      loading: true,
      currentFilters: {},
      showOptsPanel: false,
      filters: null,
      topBarVisible: false,
    };
  },
  computed: {
    heatMapInitialFilters() {
      return this.$store.getters.heatMapInitialFilters;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(newFilters) {
        //console.log("watch - filters - heatmap");
        if (
          JSON.stringify(newFilters) !== JSON.stringify(this.currentFilters)
        ) {
          this.currentFilters = { ...newFilters };
          this.applyFilters();
        }
      },
    },
    showOptionsPanel: {
      handler(newVal) {
        this.showOptsPanel = newVal;
      },
      immediate: true,
    },
    showOptsPanel(newVal) {
      this.$emit("update:showOptionsPanel", newVal);
    },
  },
  methods: {
    updateImageSrc(cameraId, token) {
      this.imageSrc = `${this.widget.apiUrl}/services/snapshot/${cameraId}?access_token=${token}`;
      //console.log("Image src updated to:", this.imageSrc);
    },
    async fetchEvents(cameraId) {
      try {
        let params = {
          "cameras[]": cameraId,
          "event_type[]": this.filters.event_type,
          from: this.filters.from || "",
          to: this.filters.to || "",
        };

        //Metadata filter parse
        if (this.filters.data && Array.isArray(this.filters.data)) {
          // Metadata filter parse
          this.filters.data.forEach((item) => {
            let [key, value] = item.split(",");
            if (value.includes("|")) {
              params[`data[${key}]`] = value;
            } else {
              if (!params[`types[]`]) {
                params[`types[]`] = [];
              }
              params[`types[]`].push(value);
            }
          });
        }

        //console.log("params:", params);
        const eventsResponse = await axios.get("/detection_event", { params });
        this.events = eventsResponse.data.data;
        //console.log("Events fetched:", this.events);
        this.drawHeatmap();
      } catch (error) {
        console.error("Error loading heatmap data:", error);
      }
    },
    fetchModelOptions(model, loadOptions = false) {
      return axios.get("charts-api/get-model-options", {
        params: { model, load_options: loadOptions },
      });
    },
    async loadData() {
      //console.log("loadData Heat map filters", this.filters);
      this.loading = true;
      try {
        const data = await this.fetchModelOptions(this.widget.model);
        this.modelOptions = data.data.data;
        this.fields = this.modelOptions.fields;
        this.$store.dispatch("setFilters", this.filters); // Actualiza los filtros en el store
        this.$emit("loading", false);
        this.$emit("updated-config");

        // Aquí no hacemos la comparación de filtros, solo actualizamos los datos necesarios
        const cameraId = this.filters?.id_camera_proxy?.[0];
        if (cameraId) {
          this.updateImageSrc(cameraId, this.widget.token);
          await this.fetchEvents(cameraId);
        }
      } catch (error) {
        console.error("Error loading model options:", error);
      } finally {
        this.loading = false;
      }
    },
    initializeResizeObserver() {
      const imageElement = this.$refs.heatmapImage;
      if (imageElement instanceof HTMLElement) {
        this.resizeObserver = new ResizeObserver(() => {
          this.onResize();
        });
        this.resizeObserver.observe(imageElement);
      }
    },
    onImageLoad() {
      this.imageLoaded = true;
      this.loading = false;
      this.$nextTick(() => {
        this.initializeHeatmap();
      });
    },
    initializeHeatmap() {
      this.updateHeatmapContainerSize();
      this.clearHeatmapOverlay(); // Limpiar el overlay antes de inicializar el heatmap
      this.heatmapInstance = h337.create({
        container: this.$refs.heatmapOverlay,
        radius: 20,
        maxOpacity: 0.6,
        minOpacity: 0,
        blur: 0.75,
        gradient: {
          0.0: "rgba(0, 0, 255, 0)",
          0.5: "rgba(0, 255, 0, 0.5)",
          1.0: "rgba(255, 0, 0, 0.6)",
        },
      });
      this.drawHeatmap();
    },
    clearHeatmapOverlay() {
      const overlayElement = this.$refs.heatmapOverlay;
      while (overlayElement.firstChild) {
        overlayElement.removeChild(overlayElement.firstChild);
      }
    },
    updateHeatmapContainerSize() {
      const imageElement = this.$refs.heatmapImage;
      const overlayElement = this.$refs.heatmapOverlay;
      if (imageElement && overlayElement) {
        overlayElement.style.width = `${imageElement.clientWidth}px`;
        overlayElement.style.height = `${imageElement.clientHeight}px`;
      }
    },
    onResize() {
      if (this.imageLoaded) {
        this.updateHeatmapContainerSize();
        this.drawHeatmap();
      }
    },
    drawHeatmap() {
      if (!this.heatmapInstance || !this.imageLoaded) {
        return;
      }
      const imageElement = this.$refs.heatmapImage;
      const imageWidth = imageElement.clientWidth;
      const imageHeight = imageElement.clientHeight;

      const heatmapData = this.events.map((event) => {
        //console.log("event", event);
        const rect = event.metadata[0].rect;
        let absoluteX, absoluteY;

        if (event.event_type === "fr") {
          absoluteX = (rect.y * this.resolutionHeight) / 100;
          absoluteY = (rect.x * this.resolutionWidth) / 100;
        } else {
          absoluteX = (rect.x * this.resolutionWidth) / 100;
          absoluteY = (rect.y * this.resolutionHeight) / 100;
        }

        const scaledX = Math.round(
          (absoluteX / this.resolutionWidth) * imageWidth
        );
        const scaledY = Math.round(
          (absoluteY / this.resolutionHeight) * imageHeight
        );

        const radius = Math.round(
          Math.max(
            (rect.width / this.resolutionWidth) * imageWidth,
            (rect.height / this.resolutionHeight) * imageHeight
          ) / 2
        );

        return {
          x: scaledX,
          y: scaledY,
          value: 1,
          radius: radius * 2,
        };
      });

      this.heatmapInstance.setData({
        max: 1,
        data: heatmapData,
      });
    },

    applyFilters() {
      console.log("applyFilters Heatmap Widget", this.widget)
      this.$root.$el.dispatchEvent(
        new CustomEvent("filters-updated", {
          detail: { data: JSON.parse(JSON.stringify(this.widget)) },
        })
      );
      this.loadData(); // Asegura que se recarguen los datos con los nuevos filtros
    },
    removeFilters() {      
      //don't remove camera selected
      const cameraFilter = {
        id_camera_proxy: this.filters?.id_camera_proxy ? [...this.filters.id_camera_proxy] : [],
      };

      this.filters = cameraFilter;
      this.applyFilters();
    },
    shareData() {
      // console.log("share-data HEATMAP, params", this.widget);
      this.$root.$el.dispatchEvent(
        new CustomEvent("share-data", {
          detail: { data: JSON.parse(JSON.stringify(this.widget)) },
        })
      );
    },
  },
  mounted() {
    //console.log("Image Heat Map - mounted - filters", this.filters);
    // console.log(
    //   "Image Heat Map - mounted -  widget filters",
    //   this.widget.filters
    // );
    this.topBarVisible = true;
    const initialFilters = { ...this.heatMapInitialFilters };
    console.log("initialFilters", initialFilters);
    if (initialFilters && Object.keys(initialFilters).length > 0) {
      this.filters = initialFilters;
      this.currentFilters = initialFilters; // Aseguramos que currentFilters también se inicialice
    } else {
      // console.log(
      //   "Image Heat Map - mounted -  widget filters 2",
      //   this.widget.filters
      // );
      this.filters = { ...this.widget.filters };
      delete this.filters.data; //temporal para borrar filtros de metadata
      this.currentFilters = { ...this.widget.filters };
      delete this.currentFilters.data;
    }
    console.log("Image Heat Map - mounted - filters 2", this.filters);
    this.loadData();
    this.$nextTick(() => {
      this.initializeResizeObserver();
    });
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};
</script>

<style scoped>
.heatmap-container {
  position: relative;
  width: 100%;
  height: auto;
}

.heatmap-image {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
}

.heatmap-overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #333;
}

.debug-section {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

.debug-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
