<template>
    <div class="omvcsdk-icon" :style="`fill: ${!darkMode ? color : '#fff'}; width: ${size}px; height: ${size}px;`">
        <component :is="icon" />
    </div>
</template>

<script>
import TimesIcon from './times-icon.vue';
import GearIcon from './gear-icon.vue';
import TrashIcon from './trash-icon.vue';
import ShareIcon from './share-icon.vue';
import ClearFilters from './clear-filters.vue';
import CircleIcon from './circle-icon.vue';
import SaveIcon from './save-icon.vue';

export default {
    components: {
        TimesIcon,
        GearIcon,
        TrashIcon,
        ShareIcon,
        ClearFilters,
        CircleIcon,
        SaveIcon
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: '#000',
        },
        size: {
            type: String | Number,
            default: 20,
        },
    },
    computed: {
        darkMode() {
            return this.$store.state.config.darkMode;
        }
    },
};
</script>
