<template>
    <tr>
        <td colspan="3">
            <p>{{ $t('optionsPanel.metadata') }}</p>
            <table class="table">
                <tr>
                    <th style="width: 30%" class="detection-event-th">
                        {{ $t('optionsPanel.metadataFilter.field5.label') }}
                    </th>
                    <td style="width: 70%">
                        <v-select
                            :options="objectTypes"
                            v-model="objectType"
                            :reduce="(val) => val.value"
                            :placeholder="$t('optionsPanel.metadataFilter.field5.label')"
                            class="w-100 my-1"
                            @option:selected="onObjectTypeSelected"
                        />
                    </td>
                </tr>

                <template v-if="objectType">
                    <tr :key="objectType">
                        <th style="width: 30%" class="detection-event-th">{{ selectedObjectType.label }}</th>
                        <td style="width: 70%">
                            <div class="" bkp="d-flex d-flex flex-wrap">
                                <div
                                    v-for="(attr, otIndex) in selectedObjectType.attrs"
                                    :key="otIndex"
                                    class="w-100 pr-0 my-2"
                                >
                                    <span class="mb-1">
                                        {{ attr.label }}
                                    </span>
                                    <custom-field
                                        :type="attr.value"
                                        :config="attr.config"
                                        :ext-filters="{}"
                                        v-model="selectedValues.objectType[objectType][attr.value]"
                                        @input="onCustomFieldChange"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </td>
    </tr>
</template>

<script>
import vSelect from 'vue-select';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es.js';
import axios from '@axios';
import moment from 'moment';
import debounce from 'lodash.debounce';

import 'flatpickr/dist/flatpickr.css';
import CustomField from './CustomField.vue';
import { transformMetadataFilters } from './helpers';

export default {
    components: {
        vSelect,
        CustomField,
    },
    props: {
        modelData: {
            type: Object,
            default: null,
        },
        // Filters
        value: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            cameras: [],
            dateSelectorFrom: null,
            dateSelectorTo: null,
            providers: [],
            detections: [],
            objectTypes: [],
            objectType: null,
            selectedValues: {
                provider: null,
                detection: null,
                cameras: [],
                from: null,
                to: null,
                objectTypes: {},
                objectType: {},
            },
        };
    },
    async mounted() {
        try {
            const { data } = await axios.get('services/detection-events/get-catalog');

            this.buildSelects(data.data);

            const cameraField = this.modelData.fields.find((f) => f.value === 'id_camera_proxy');
            for (const id in cameraField.options) this.cameras.push(id);

            this.setLoadedFilters();
        } catch (error) {
            console.error(error);
        }
    },
    computed: {
        detectionOptions() {
            if (!this.selectedValues.provider) return [];

            return this.detections.filter((d) => d.provider === this.selectedValues.provider);
        },
        selectedObjectType() {
            if (this.objectTypes.length === 0 && !this.objectType) return null;

            const selectedOT = this.objectTypes.filter((ot) => ot.value === this.objectType)[0];

            return selectedOT;
        },
    },
    watch: {
        objectType(value) {
            if (!value) this.removeDataFilters(this.value);
        },
    },
    methods: {
        setLoadedFilters() {
            if (this.value && this.value.data) {
                this.$nextTick(() => {
                    const dataFilters = transformMetadataFilters(this.value.data);
                    const typeData = dataFilters.data[dataFilters.type];
                    this.objectType = dataFilters.type;
                    this.selectedValues.objectType[dataFilters.type] = typeData;
                });
            }
        },
        getCameras() {
            this.cameras = [];
        },
        onCustomFieldChange: debounce(function () {
            this.search();
        }, 800),
        setCameras(camera) {
            if (!this.cameras.find((c) => c.uuid === camera.uuid)) {
                this.cameras.push(camera);
            }
        },
        buildSelects(data) {
            data.providers.forEach((provider) => {
                this.addProvider(provider);
                this.addDetections(provider);
            });
            this.buildObjectTypes(data.object_types);
        },
        addProvider(provider) {
            this.providers.push({
                label: provider.label,
                value: provider.value,
            });
        },
        addDetections(provider) {
            const detections = provider.detections.map((detection) => {
                return {
                    provider: provider.value,
                    label: detection.label,
                    value: detection.value,
                };
            });
            this.detections.push(...detections);
        },
        buildObjectTypes(objectTypes) {
            this.objectTypes = objectTypes;
            objectTypes.forEach((obt) => {
                this.selectedValues.objectTypes[obt.value] = {};
            });
        },
        initializeDateTimePickers() {
            const config = {
                minDate: moment().subtract(15, 'days').toDate(),
                maxDate: moment().toDate(),
                altInput: true,
                dateFormat: 'Y-m-d H:i',
                enableTime: true,
                time_24hr: true,
                locale: this.$i18n.locale === 'en' ? null : Spanish,
            };

            const dateSelectorFrom = '#date_selector_from';
            this.dateSelectorFrom = flatpickr(dateSelectorFrom, { ...config });

            const dateSelectorTo = '#date_selector_to';
            this.dateSelectorTo = flatpickr(dateSelectorTo, { ...config });
        },
        fetchCatalog() {
            return axios.get('v1/services/detection-events/get-catalog');
        },
        onShown() {
            this.initializeDateTimePickers();
            this.getCameras();
            this.resetFilters(); // Temp fix
        },
        resetFilters() {
            for (const key in this.selectedValues.objectType) {
                if (Object.hasOwnProperty.call(this.selectedValues.objectType, key)) {
                    this.selectedValues.objectType[key] = {};
                }
            }
        },
        onObjectTypeSelected() {
            this.selectedValues.objectType = {};
            const objectType = this.objectTypes.filter((ot) => ot.value === this.objectType)[0];
            this.selectedValues.objectType[this.objectType] = {};

            objectType.attrs.forEach((attr) => (this.selectedValues.objectType[this.objectType][attr.value] = null));

            return objectType;
        },
        search() {
            const selectedValues = this.selectedValues;
            let filters = [];

            this.value = this.removeDataFilters(this.value);

            if (this.objectType) {
                for (const objectType in selectedValues.objectType) {
                    const properties = selectedValues.objectType[objectType];

                    for (const property in properties) {
                        if (properties.hasOwnProperty(property)) {
                            const object = properties[property];

                            if (!object || object.value === null) continue;

                            let value;

                            if (Array.isArray(object.value)) {
                                value = object.value.map((v) => v.value).join();
                            } else if (typeof object.value === 'object' && 'value' in object.value) {
                                value = object.value.value;
                            } else {
                                value = object.value;
                            }

                            filters.push(`${property},${object.operator}|${encodeURIComponent(value)}`);
                        }
                    }
                }
            }

            filters.push(`type,${this.objectType}`);
            this.value[`data`] = filters;
            this.$emit('update', this.value);
        },
        removeDataFilters(filters) {
            for (let filter in filters) {
                if (filter.includes('data')) {
                    delete filters[filter];
                }
            }
            return filters;
        },
    },
};
</script>

<style></style>
