export default {
    namespaced: false,
    metadata: {
        type: 'Tipo',
        gender: 'Gênero',
        female: 'Feminino',
        male: 'Masculino',
        color: 'Cor',
        confidence: 'Confiança',
        plate: 'Placa',
        brand: 'Marca',
        model: 'Modelo',
        class: 'Classe',
        person: 'Pessoa',
        car: 'Veículo'
    }
}
