import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex';
import GMaps from '@/components/GMaps';
import vSelect from 'vue-select'
import i18n from '@/libs/i18n'
import './scss/app.scss'

Vue.config.productionTip = false
Vue.use(Vuex);
Vue.component('v-select', vSelect)

export default class OmniviewsChartSdk {

  constructor(selector, config = {}) {
    this.selector = selector;
    this.config = config;
  }

  init() {

    // Store
    const store = new Vuex.Store({
      state: {
        sensorsView: false,
        config: {
          id: null,
          name: '',
          model: null,
          field: null,
          data: {},
          chart: null,
          filters: {},
          chartConf: {
            dateGroupFieldWildcard: 'day',
            dateFilterWildcard: 'month',
          },
          keep_filters: [], // This is used and assigned from SBO to avoid deleting the listed filters.
          darkMode: this.config.darkMode || false,
          eventMsUrl: this.config.eventMsUrl || '',
          apiUrl : this.config.apiUrl || ''
        },
        heat_map_initial_filters: {},
        viewport: {
          height: 400,
          width: 400,
        }
      },
      getters: {
        config(state) {
          return state.config;
        },
        heatMapInitialFilters(state) {
          return state.heat_map_initial_filters;
        },
        viewport: state => state.viewport,
      },
      mutations: {
        setDateRange(state, dates) {
          state.fromDate = dates.fromDate
          state.toDate = dates.toDate
        },
        setConfig(state, data) {
          state.config = Object.assign(state.config, { ...data });
        },
        setChartConf(state, data) {
          state.config.chartConf = data;
        },
        setFilters(state, data) {
          state.config.filters = data;
        },
        setKeepFilters(state, data) {
          state.config.keep_filters = data;
        },
        setSensorsView(state, data) {
          state.sensorsView = data;
        },
        setDarkMode(state, data) {
          state.config.darkMode = data;
        },
        setViewport(state, { height, width }) {
          state.viewport.height = height;
          state.viewport.width = width;
        },
        setHeatMapInitialFilters(state, data) {
          state.heat_map_initial_filters = data;
        },
      },
      actions: {
        setConfig({ commit }, data) {
          commit('setConfig', data);
        },
        setChartConf({ commit }, data) {
          commit('setChartConf', data);
        },
        setFilters({ commit }, data) {
          commit('setFilters', data);
        },
        setKeepFilters({ commit }, data) {
          commit('setKeepFilters', data);
        },
        setSensorsView({ commit }, data) {
          commit('setSensorsView', data);
        },
        setDarkMode({ commit }, data) {
          commit('setDarkMode', data);
        },
        setViewport({ commit }, data) {
          commit('setViewport', data);
        },
        setHeatMapInitialFilters({ commit }, data) {
          commit('setHeatMapInitialFilters', data);
        },
      },
      modules: {
      }
    });

    // App instance
    this.instance = new Vue({
      store,
      i18n,
      components: { App },
      data: () => ({ config: {} }),
      render: function (createElement) {
        return createElement('app', {
          props: { config: this.config }
        });
      }
    });

    Vue.use(GMaps, {
      apiKey: this.config.gmapsKey,
    });

    Vue.set(this.instance, 'config', {
      ...this.config,
      id: this.selector.replace('#', '')
    });

    // Mount app
    this.instance.$mount(this.selector);

    // Force dark mode from options
    this.instance.$store.dispatch('setDarkMode', this.config.darkMode);

    return this.instance;
  }

  setDarkMode() {
    this.instance.$store.dispatch('setDarkMode', true);
  }

  setLightMode() {
    this.instance.$store.dispatch('setDarkMode', false);
  }

  destroy() {
    this.instance.$destroy();
  }
}