import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// =============================================
// This doesn't work at the moment use src/OmniviewChartsSdk.js
// to config the store.
// =============================================

export default new Vuex.Store({
  state: {
    // Dates
    fromDate: null,
    toDate: null,
    config: {
      name: '',
      model: null,
      field: null,
      date_field: null,
      chart: null,
    }
  },
  getters: {
  },
  mutations: {
    setDateRange(state, dates) {
      state.fromDate = dates.fromDate
      state.toDate = dates.toDate
    },
    setConfig(state, data) {
      state.config = Object.assign(state.config, { ...data });
    }
  },
  actions: {
    setConfig({ commit }, data) {
      commit('setConfig', data);
    }
  },
  modules: {
  }
})
