export default {
    namespaced: false,
    metadata: {
        type: 'Type',
        gender: 'Gender',
        female: 'Female',
        male: 'Male',
        color: 'Color',
        confidence: 'Confidence',
        plate: 'Plate',
        brand: 'Brand',
        model: 'Model',
        class: 'Clase',
        person: 'Person',
        car: 'Vehicle',
    }
}