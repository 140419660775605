export default {

    methods: {
        generateColors(requiredColors) {
            const baseColors = this.darkMode
                ? ["#CECFD0", "#4294cb", "#F1F1F3", "#c0cc18"]
                : ["#385d7b", "#4294cb", "#d42b2b", "#a4afb7"];

            const colors = [...baseColors];
            let variationLevel = 1;

            while (colors.length < requiredColors) {
                const blockType = colors.length / baseColors.length;
                const isLightBlock = Math.floor(blockType) % 2 === 0;
                const variationFactor = variationLevel * 15;
                const saturationVariation = variationLevel * 12;

                baseColors.forEach((color) => {
                    const variation = this.createColorVariation(
                        color,
                        isLightBlock,
                        variationFactor,
                        saturationVariation
                    );
                    colors.push(variation);
                });

                if (isLightBlock) {
                    variationLevel++;
                }
            }

            return colors.slice(0, requiredColors); // En caso de generar más colores de los necesarios
        },

        createColorVariation(
            color,
            makeLight,
            variationFactor,
            saturationVariation
        ) {
            const hsl = this.hexToHsl(color);
            const newH = hsl.h; // Mantener el mismo tono
            const newS = Math.min(
                Math.max(
                    hsl.s + (makeLight ? saturationVariation : -saturationVariation),
                    0
                ),
                100
            ); // Variar la saturación
            const newL = makeLight
                ? Math.min(hsl.l + variationFactor, 100) // Aclarar la luminosidad
                : Math.max(hsl.l - variationFactor, 0); // Oscurecer la luminosidad
            return this.hslToHex(newH, newS, newL);
        },

        hexToHsl(hex) {
            let r = 0,
                g = 0,
                b = 0;
            if (hex.length === 4) {
                r = parseInt(hex[1] + hex[1], 16);
                g = parseInt(hex[2] + hex[2], 16);
                b = parseInt(hex[3] + hex[3], 16);
            } else if (hex.length === 7) {
                r = parseInt(hex[1] + hex[2], 16);
                g = parseInt(hex[3] + hex[4], 16);
                b = parseInt(hex[5] + hex[6], 16);
            }
            r /= 255;
            g /= 255;
            b /= 255;
            const max = Math.max(r, g, b),
                min = Math.min(r, g, b);
            let h,
                s,
                l = (max + min) / 2;

            if (max === min) {
                h = s = 0;
            } else {
                const d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                }
                h /= 6;
            }
            return { h: h * 360, s: s * 100, l: l * 100 };
        },

        hslToHex(h, s, l) {
            s /= 100;
            l /= 100;
            const c = (1 - Math.abs(2 * l - 1)) * s;
            const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
            const m = l - c / 2;
            let r = 0,
                g = 0,
                b = 0;

            if (0 <= h && h < 60) {
                r = c;
                g = x;
                b = 0;
            } else if (60 <= h && h < 120) {
                r = x;
                g = c;
                b = 0;
            } else if (120 <= h && h < 180) {
                r = 0;
                g = c;
                b = x;
            } else if (180 <= h && h < 240) {
                r = 0;
                g = x;
                b = c;
            } else if (240 <= h && h < 300) {
                r = x;
                g = 0;
                b = c;
            } else if (300 <= h && h < 360) {
                r = c;
                g = 0;
                b = x;
            }
            r = Math.round((r + m) * 255);
            g = Math.round((g + m) * 255);
            b = Math.round((b + m) * 255);

            return `#${this.componentToHex(r)}${this.componentToHex(
                g
            )}${this.componentToHex(b)}`;
        },

        componentToHex(c) {
            const hex = c.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        },
    },

}