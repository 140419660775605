<template>
  <div
    :class="[
      `omvcsdk charts-sdk-main-content ${config.id}`,
      { 'omvcsdk-dark-mode': darkMode },
    ]"
    ref="viewportRef"
  >
    <dashboard :chart-id="chartId" :chart-data="chartData" />
  </div>
</template>

<script>
import axiosIns from "@axios";
import Dashboard from "./components/Dashboard.vue";
import store from "./store";

export default {
  name: "App",
  components: {
    Dashboard,
  },
  props: {
    config: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      chartId: null,
      chartData: null,
      viewportRef: null,
    };
  },
  computed: {
    darkMode() {
      return this.$store.state.config.darkMode;
    },
  },
  created() {
    const locale = this.config.locale || "es";
    axiosIns.interceptors.request.use(
      (config) => {
        if (this.config.apiUrl) {
          config.baseURL = this.config.apiUrl;
        }

        if (this.config.token) {
          config.headers.Authorization = `Bearer ${this.config.token}`;
        }

        config.headers["X-localization"] = locale;

        return config;
      },
      (error) => Promise.reject(error)
    );

    if (this.config.chartId) {
      this.chartId = this.config.chartId;
    }

    if (this.config.chartData) {
      this.chartData = this.config.chartData;

      if (this.config.chartData.keep_filters) {
        this.$store.dispatch(
          "setKeepFilters",
          this.config.chartData.keep_filters || []
        );
      }
    }
    this.$i18n.locale = locale;
    this.initResizeObserver();
  },
  methods: {
    /**
     * Initializes the ResizeObserver to track changes in the viewport size.
     * If the viewportRef is null, the function returns early.
     * The ResizeObserver instance is created to observe the viewportRef element.
     * When a resize event occurs, the height and width of the viewport are extracted from the entry object.
     * The setViewport action is dispatched to update the viewport height and width in the store.
     * The observer is cleaned up when the component is destroyed.
     */
    initResizeObserver() {
        if (!this.viewportRef) return;
        
        const resizeObserver = new ResizeObserver((entries) => {
            const [entry] = entries;
            const { height, width } = entry.contentRect;
            this.$store.dispatch("setViewport", { height, width });
        });
        
        resizeObserver.observe(this.viewportRef);

        this.$once("hook:beforeDestroy", () => {
            resizeObserver.unobserve(this.viewportRef);
            resizeObserver.disconnect();
        });
    },
  },

  mounted() {
    this.viewportRef = this.$refs.viewportRef;
    this.initResizeObserver();
  },
};
</script>

<style lang="scss" scoped>
.omvcsdk {
  min-height: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
