<template>
    <div class="dashboard">
        <template v-if="(chartId || chartData) && !notFound">
            <template v-if="!loading">
                <widget :data="config" />
            </template>
            <template v-else> {{ $t('app.loading') }}... </template>
        </template>
        <template v-if="!chartId && !chartData">
            <widget v-if="currentTab === 'widget'" :data="config" />
            <form-component v-if="currentTab === 'config'" @form-complete="onFormComplete" />
        </template>
        <template v-if="notFound">
            <div class="d-flex justify-content-center align-content-center p-5">
                <h2>{{ $t('app.chartsNotFound') }}</h2>
            </div>
        </template>
    </div>
</template>

<script>
import axios from '@axios';
import Form from './Form.vue';
import Widget from './Widget.vue';

export default {
    components: {
        Widget,
        'form-component': Form,
    },
    props: {
        chartId: {
            type: Number | String,
            default: null,
        },
        chartData: {
            type: Object,
        },
    },
    data() {
        return {
            currentTab: 'config',
            widgetData: null,
            loading: true,
            notFound: false,
        };
    },
    async created() {
        if (this.chartId) {
            try {
                const { data } = await axios.get(`/services/user-charts/${this.chartId}`);
                const chartData = data.data;

                if (chartData) {
                    this.$store.dispatch('setConfig', chartData.data);
                }
            } catch (error) {
                if (error.response.status === 404) {
                    this.notFound = true;
                } else {
                    console.error('ChartsApiWidget', error);
                }
            } finally {
                this.loading = false;
            }

            return true;
        }

        if (this.chartData) {
            this.$store.dispatch('setConfig', this.chartData);
            this.loading = false;

            return true;
        }
    },
    mounted() {
        this.currentTab = 'config';
    },
    computed: {
        config() {
            return this.$store.state.config;
        },
    },
    methods: {
        onFormComplete() {
            this.currentTab = 'widget';
        },
    },
};
</script>