<template>
    <g-maps
        :points="points"
        :height="height"
        :lat="19.39068"
        :lng="-99.2836975"
        :options="options"
        :initial-zoom="10"
    />
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        chartData: Array | Object,
    },
    data() {
        return {
            options: {
                dissipating: true,
                radius: 30,
            },
        };
    },
    computed: {
        points() {
            const items = Object.values(this.chartData);

            if (!items.length || (items.length && !items[0].data)) {
                return [];
            }

            return items.map((value) => value.data.location);
        },
    },
};
</script>
<style></style>
