<template>
    <div class="chart-main-container">
        <div class="chart-container">
            <!-- ============================== Chart ============================== -->
            <div class="d-flex justify-content-around" style="margin: 0 auto; width: 180px">
                <template v-if="topBarVisible && !currentField.percentage">
                    <p class="text-center" v-if="this.sensorData">
                        {{ this.sensorData.last_measurement }} {{ this.sensorData.swift_unit_measure.abbrev }}
                    </p>
                </template>
                <template v-if="JSON.stringify(filters) !== '{}'">
                    <span> | </span>
                    <a href="#" @click.prevent="removeFilters" class="text-danger">
                        <icon-component icon="clear-filters" size="18" />
                    </a>
                </template>
            </div>
            <div class="text-center">
                {{ filterDateRange }}
            </div>
            <!-- <div class="text-center mb-1" v-if="appliedFilters">
                {{ $t('app.appliedFilters') }}: {{ appliedFilters }}
            </div> -->
            <template v-if="loadedData">
                <line-chart class="chart" v-if="loadedData" :options="chartOptions" :chart-data="chartData" />
            </template>
            <!-- <div class="h-100 d-flex justify-content-center mt-5" v-if="!loadedData || !total">
                <p class="h3">{{ $t('app.noInformationAvailable') }}</p>
            </div> -->
        </div>
        <!-- ============================== Options panel ============================== -->
        <options-panel-sensors
            :show.sync="showOptsPanel"
            :widget="widget"
            :fields="fields"
            :modelOptions="modelOptions"
            :filters.sync="filters"
            :selected-chart-value.sync="selectedChartValue"
            @apply-filters="applyFilters"
        />
    </div>
</template>

<script>
import axios from '@axios';
import moment from 'moment';
import LineChart from './LineChart';
import OptionsPanelSensors from './OptionsPanelSensors.vue';
import IconComponent from '../icons/icon-component.vue';
import services from '@/libs/services';

export default {
    name: 'DailyCount',
    components: {
        LineChart,
        OptionsPanelSensors,
        IconComponent,
    },
    props: {
        widget: {
            required: true,
            type: Object,
        },
        showOptionsPanel: {
            type: Boolean,
            default: false,
        },
        refreshData: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        showOptsPanel: false,
        loadedData: null,
        total: 0,
        loading: false,
        params: null,
        modelOptions: null,
        filters: {},
        availableFilters: [],
        filtersArray: [],
        fields: [],
        fieldsOptions: [],
        colors: [],
        selectedChartValue: null,
        intervalId: null,
        appliedFilters: null,
        topBarVisible: true,
        selectedFieldName: '',
        sensorData: null,
        filterDateRange: null,
    }),
    created() {
        this.$store.dispatch('setSensorsView', true);
        if (this.widget.id) {
            if (this.widget.filters && !Array.isArray(this.widget.filters)) {
                this.filters = this.widget.filters;
                this.showDateRangeFilter();
            } else {
                this.filters = {};
            }
        }
    },
    async mounted() {
        const modelOptions = await this.fetchModelOptions(this.widget.model);
        this.modelOptions = modelOptions.data.data;
        this.fields = this.modelOptions.fields;
        this.availableFilters = this.modelOptions.filters;
        this.selectedFieldName = this.modelOptions.fields[0].options[this.widget.data.sensor_id];

        this.loadData();
    },
    watch: {
        showOptionsPanel(value) {
            this.showOptsPanel = value;
        },
        showOptsPanel(value) {
            this.$emit('update:showOptionsPanel', value);
        },
    },
    methods: {
        fetchModelOptions(model, loadOptions = false) {
            return axios.get('charts-api/get-model-options', { params: { model, load_options: loadOptions } });
        },
        loadData() {
            const sensor_id = this.activeFilters.id ? this.activeFilters.id[0] : this.widget.data.sensor_id;

            const from = this.activeFilters.from;
            const to = this.activeFilters.to;
            const dates = from && to ? { from, to } : {};
            this.selectedFieldName = this.modelOptions.fields[0].options[sensor_id];

            services.getSensorData(sensor_id, dates).then(({ data }) => {
                const resp = data.data;
                this.sensorData = resp;
                const timeSeriesData = resp.time_series.timeSeriesData[0].samples;

                this.$nextTick(() => {
                    this.loadedData = {};
                    timeSeriesData.forEach((item, index) => {
                        const dateTime = moment(item[0] * 1000).format('HH:mm');
                        const value = item[1];
                        this.loadedData[`${index}_${dateTime}`] = value;
                    });
                });
            });

            this.$store.dispatch('setFilters', this.activeFilters);
            this.$emit('updated-config');
        },
        async applyFilters() {
            this.$root.$el.dispatchEvent(
                new CustomEvent('filters-updated', {
                    detail: { data: JSON.parse(JSON.stringify(this.params)) },
                })
            );
            this.loadData();
            this.showDateRangeFilter();
        },
        showDateRangeFilter() {
            if (this.filters.from && this.filters.to) {
                const from = moment(this.filters.from).startOf('day');
                const to = moment(this.filters.to).startOf('day');

                if (from.isSame(to)) {
                    this.filterDateRange = from.format('YYYY-MM-DD');
                } else {
                    this.filterDateRange = `${from.format('YYYY-MM-DD')} - ${to.format('YYYY-MM-DD')}`;
                }
            } else {
                this.filterDateRange = null;
            }
        },
        removeFilters() {
            this.filters = {};
            this.applyFilters();
        },
    },
    computed: {
        currentField() {
            const field = this.fields.find((f) => f.value === this.widget.field);

            if (!field) {
                return '';
            }

            return field;
        },
        activeFilters() {
            return this.filters;
        },
        totalFormatted() {
            if (!this.total) return 0;
            return new Intl.NumberFormat('en-US').format(this.total);
        },
        annotationsConfig() {
            if (!this.sensorData) return [];

            const colors = {
                maxCritical: 'red',
                minCritical: 'red',
                maxWarning: '#ffd800',
                minWarning: '#ffd800',
            };

            if (!this.sensorData.threshold) return [];

            const threshold = this.sensorData.threshold;
            const annotations = [];
            const props = ['maxCritical', 'maxWarning', 'minCritical', 'minWarning'];

            props.forEach((p) => {
                if (threshold[p] !== null) {
                    annotations.push({
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y',
                        value: threshold[p],
                        borderColor: colors[p],
                        borderWidth: 2,
                        borderDash: [5, 5],
                    });
                }
            });

            return annotations;
        },
        chartLabels() {
            return Object.keys(this.loadedData);
        },
        chartOptions() {
            return {
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: false,
                        },
                        grid: {
                            display: false,
                        },
                        // min: 0,
                        // max: 100,
                        // stepSize: 10,
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    annotation: {
                        annotations: this.annotationsConfig,
                    },
                    tooltip: {
                        intersect: false,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            };
        },
        chartDataValues() {
            return this.chartLabels.map((label) => {
                return this.loadedData[label];
            });
        },
        chartData() {
            if (!this.loadedData) return;
            return {
                labels: this.chartLabels.map((l) => {
                    return l.split('_')[1];
                }),
                datasets: [
                    {
                        label: this.selectedFieldName,
                        backgroundColor: '#60A5FA',
                        data: this.chartDataValues,
                        tension: 0.1,
                    },
                ],
            };
        },
    },
};
</script>
