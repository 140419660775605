import GMaps from './GMaps.vue';
import Loader from './loader.js';

GMaps.install = function(Vue, options) {
  Vue.component(GMaps.name, GMaps);

  if (!options) {
    console.error('No Google Map API key provided.');
    return;
  }

  if (options.apiKey) {
    Loader.load(options.apiKey);
  }
};

export default GMaps;
