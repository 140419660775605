<template>
  <div
      class="d-flex w-100"
      :class="selectedOption === 'custom' ? 'mb-2' : 'mb-0'"
  >
    <label
        v-for="option in options"
        :key="option.value"
        class="mb-0 mx-auto d-flex flex-column"
        :for="`${type}-${option.value}`"
        :class="{active: selectedOption === option.value}"
    >
      <input
          :id="`${type}-${option.value}`"
          type="radio"
          class="mb-2"
          autocomplete="off"
          :value="option.value"
          v-model="selectedOption"
          @input="onSelectOption"
      />
      {{ option.label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "DateWildcards",
  props: {
    type: {
      type: String,
      default: 'filters',
    },
    value: String,
  },
  data() {
    return {
      selectedOption: "month",
      groupOptions: [
        {label: this.$t("month"), value: "month"},
        {label: this.$t("day"), value: "day"},
        {label: this.$t("hour"), value: "hour"},
      ],
      filterOptions: [
        {label: this.$t("today"), value: "today"},
        {label: this.$t("year"), value: "year"},
        {label: this.$t("month"), value: "month"},
        {label: this.$t("week"), value: "week"},
        {label: this.$t("custom"), value: "custom"},
      ],
    };
  },
  created() {
    this.selectedOption = this.value;
  },
  watch: {
    value(val) {
      this.selectedOption = val;
    }
  },
  computed: {
    options() {
      if (this.type === "groupField") {
        return this.groupOptions;
      }

      return this.filterOptions;
    }
  },
  methods: {
    onSelectOption(event) {
      this.$emit('input', event.target.value);
      this.$emit('option:selected', event.target.value);
    }
  }
}
</script>

<style scoped>

</style>