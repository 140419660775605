<template>
    <div class="formComponent">
        <form id="form" name="form" method="POST" class="" @submit="onSubmit">
            <div class="form-group">
                <label for="name" class="form-label"> {{ $t('form.name') }} </label>
                <input id="name" name="name" type="text" class="form-control" v-model="formData.name" required />
            </div>
            <div class="form-group">
                <label for="model" class="form-label"> {{ $t('form.model') }} </label>
                <select id="model" name="model" class="form-control" v-model="formData.model" required>
                    <option value="" disabled selected>{{ $t('form.selectModel') }}</option>
                    <option v-for="(model, index) in models" :key="index" :value="model.value">
                        {{ model.text }}
                    </option>
                </select>
            </div>
            <div class="form-group" v-if="!isSwiftSensor">
                <label for="field" class="form-label"> {{ $t('form.field') }} </label>
                <select
                    id="field"
                    name="field"
                    class="form-control"
                    v-model="formData.field"
                    :disabled="!model"
                    required
                >
                    <option selected disabled>{{ $t('form.field') }}</option>
                    <template v-if="model">
                        <template v-for="(field, index) in model.fields">
                            <option
                                v-if="
                                    field.visible_in === 'both' || field.visible_in === 'fields'
                                "
                                :key="index"
                                :value="field.value"
                            >
                                {{ field.text }}
                            </option>
                        </template>
                    </template>
                </select>
            </div>
            <div class="form-group" v-if="selectedField && !isSwiftSensor">
                <label for="chart" class="form-label"> {{ $t('form.chart') }} </label>
                <select
                    id="chart"
                    name="chart"
                    class="form-control"
                    v-model="formData.chart"
                    :disabled="!formData.field"
                    :required="!isSwiftSensor"
                >
                    <option value="">{{ $t('form.chart') }}</option>
                    <option value="bar" v-if="selectedField.charts.includes('bar')">
                        {{ $t('optionsPanel.charts.bar') }}
                    </option>
                    <option value="pie" v-if="selectedField.charts.includes('pie')">
                        {{ $t('optionsPanel.charts.doughnut') }}
                    </option>
                    <option value="total" v-if="selectedField.charts.includes('total')">
                      Total
                    </option>
                    <option value="map" v-if="selectedField.charts.includes('map')">
                        {{ $t('optionsPanel.charts.map') }}
                    </option>
                    <option value="image_heat_map" v-if="selectedField.charts.includes('image_heat_map')" >
                        {{ $t('optionsPanel.charts.image_heatmap') }}
                    </option>
                    <option value="total_people" v-if="selectedField.charts.includes('total_people')" >
                        Total people
                    </option>
                    <option value="avg_stay" v-if="selectedField.charts.includes('avg_stay')" >
                        Average stay
                    </option>
                </select>
            </div>
            <div class="form-group" v-if="isSwiftSensor">
                <label for="model" class="form-label"> {{ $t('form.sensors') }} </label>
                <select id="model" name="model" class="form-control" v-model.number="formData.data.sensor_id" required>
                    <option value="" disabled selected>{{ $t('form.selectModel') }}</option>
                    <option v-for="(model, index) in sensors" :key="index" :value="model.value">
                        {{ model.text }}
                    </option>
                </select>
            </div>
            <div class="form-group" v-if="formData.chart === 'image_heat_map'">
              <label for="unit" class="form-label"> {{ $t('form.facility') }} </label>
              <select-paginate-unit
                :id_unit.sync="formData.unit"
                input-id="unit"
                :multiple="false"
                :notInit="true"
              />
            </div>
            <div class="form-group" v-if="formData.unit">
              <label for="camera" class="form-label"> {{ $t('form.camera') }} </label>
              <select-paginate-camera
                :id_camera.sync="formData.camera"
                :id_unit="formData.unit.value"
                input-id="camera"
                :multiple="false"
              />
            </div>
            <button ref="submit_btn" submit="true" class="" style="opacity: 0"></button>
        </form>
        <a href="#" class="btn btn-primary" @click.prevent="$refs.submit_btn.click()"> {{ $t('form.continue') }} </a>
    </div>
</template>

<script>
import axios from '@axios';
import SelectPaginateUnit from './charts/SelectPaginateUnit.vue';
import SelectPaginateCamera from './charts/SelectPaginateCamera.vue';

export default {
    name: 'form-component',
    components: {
      SelectPaginateUnit,
      SelectPaginateCamera,
    },
    data() {
        return {
            models: null,
            model: null,
            formData: {
                name: '',
                model: null,
                field: null,
                data: {},
                date_field: null,
                chart: null,
                unit: null,
                camera: null,
            },
            sensors: [],
        };
    },
    async mounted() {
        try {
            const { data: data } = await this.fetchData();
            this.models = data.data;
        } catch (error) {
            console.log('error form fetch data:', error);
        }
    },
    watch: {
        'formData.model'(val) {
            if (val) {
                this.model = this.models.find((m) => m.value === val);
            }
        },
        async isSwiftSensor(value) {
            if (value) {
                const modelOptions = await axios.get('charts-api/get-model-options', {
                    params: {
                        model: 'SwiftSensor',
                        load_options: false,
                    },
                });
                const fields = modelOptions.data.data.fields[0].options;
                const labels = Object.values(fields);
                const values = Object.keys(fields);
                this.sensors = labels.map((label, index) => ({ text: label, value: values[index] }));
                this.formData.chart = 'line';
                this.$store.dispatch('setSensorsView', value);
            } else {
                this.$store.dispatch('setSensorsView', value);
            }
        },
    },
    computed: {
        selectedField() {
            if (!this.formData.field) return null;

            const field = this.model.fields.find((field) => field.value === this.formData.field);

            return field;
        },
        isSwiftSensor() {
            return this.formData.model === 'SwiftSensor';
        },
    },
    methods: {
        fetchData() {
            return axios.get('charts-api/get-config');
        },
        onSubmit(e) {
          e.preventDefault();
          if (this.formData.chart === 'image_heat_map' && this.formData.camera) { //set image heatmap initial camera or filters if required
            let heatmapInitialFilters = { id_camera_proxy: [String(this.formData.camera.value)] };
            this.$store.dispatch('setHeatMapInitialFilters', heatmapInitialFilters);
          }
          this.$store.dispatch('setConfig', this.formData);
          this.$emit('form-complete', this.formData);
        },
    },
};
</script>

<style></style>
