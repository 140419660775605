<template>
  <div
      class="chart-main-container"
      :class="{ 'total-chart': ['total', 'total_people', 'avg_stay'].includes(widget.chart)  }"
  >
    <div ref="chartContainer" class="chart-container">
      <!-- ============================== Chart ============================== -->
      <div class="d-flex icons-share-filters" v-if="widget.field !== 'density'">
        <div class="icons-group">
          <template v-if="shouldShowClearBtn()">
            <a href="#" @click.prevent="removeFilters" class="text-danger mr-4">
              <icon-component icon="clear-filters" size="20"/>
            </a>
          </template>
          <template v-if="topBarVisible && !currentField.percentage">
            <a href="#" @click.prevent="shareData" class="ml-1 mr-1">
              <icon-component icon="share-icon" size="20"/>
            </a>
          </template>
        </div>
      </div>
      <!-- <div class="text-center mb-1" v-if="appliedFilters">
                {{ $t('app.appliedFilters') }}: {{ appliedFilters }}
            </div> -->

      <template v-if="loadedData && total">
        <component
            v-if="widget.chart === 'bar'"
            :is="chartType"
            class="chart"
            :options="chartOptions"
            :chart-data="chartData"
            style="position: relative"
            :style="'height:' + viewportHeight + 'px;'"
        />

        <div class="widgetChart" v-if="widget.chart === 'pie'">
          <div class="row">
            <div class="col-8" ref="widthCanvas">
              <!-- Total label -->
              <div
                  class="chart-container_total"
                  :style="'width:' + withTotal + '%;'"
              >
                <p class="text-center mb-1 chart-container_total_p">
                  {{ totalFormatted }}
                </p>
                <p class="text-center chart-container_total_p">
                  {{ $t("app.total") }}
                </p>
              </div>
              <component
                  :is="chartType"
                  class="chart"
                  :options="chartOptions"
                  :chart-data="chartData"
                  style="position: relative"
                  :style="'height:' + viewportHeight + 'px;'"
                  :total="totalFormatted"
              />
            </div>
            <div class="col-4 chart-legend">
              <chart-legend
                  v-if="currentField"
                  :labels="chartLabels"
                  :colors="colors"
                  :values="chartDataValues"
                  :current-field="currentField"
                  :total="totalFormatted"
                  :style="'max-height:' + viewportHeight + 'px;'"
              />
            </div>
          </div>
        </div>

        <div v-if="widget.chart === 'map'" class="widgetChart col">
          <h-map-chart
              class="chart"
              :key="Object.values(loadedData).length"
              :chart-data="loadedData"
              :height="500"
          />
        </div>

        <div id="total-chart" v-if="widget.chart === 'total'">
          <p class="display-3 font-weight-bold text-center">
            {{ totalFormatted }}<br/>
          </p>
          <p class="d-block text-center h4 mt-2">record(s)</p>
          <div class="total-wave">
            <wave-svg/>
            <div class="bottom-wave"></div>
          </div>
        </div>

        <div id="total-chart" v-if="widget.field === 'density'">
          <people-density-count :widget="widget"/>
          <div class="total-wave">
            <wave-svg/>
            <div class="bottom-wave"></div>
          </div>
        </div>
      </template>
      <div
          class="h-100 d-flex justify-content-center mt-5"
          v-if="!loadedData || !total"
      >
        <p class="h3">{{ $t("app.noInformationAvailable") }}</p>
      </div>
    </div>
    <!-- ============================== Options panel ============================== -->
    <options-panel
        :show.sync="showOptsPanel"
        :widget.sync="widget"
        :fields="fields"
        :modelOptions="modelOptions"
        :filters.sync="filters"
        :chart-conf.sync="chartConf"
        :selected-chart-value.sync="selectedChartValue"
        :keep-filters="keepFilters"
        @apply-filters="applyFilters"
    />
  </div>
</template>

<script>
import axios from "@axios";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import HMapChart from "./HMapChart.vue";
import DateSelector from "../widgets/DateSelector.vue";
import Legend from "./Legend.vue";
import OptionsPanel from "./OptionsPanel.vue";
import IconComponent from "../icons/icon-component.vue";
import colorMixin from "@/mixins/colorMixin";
import waveSvg from "../icons/wave-svg.vue";
import helpers from "@/libs/helpers";
import PeopleDensityCount from "./PeopleDensityCount.vue";

export default {
  name: "GroupCount",
  mixins: [colorMixin],
  components: {
    PeopleDensityCount,
    BarChart,
    PieChart,
    DateSelector,
    "chart-legend": Legend,
    OptionsPanel,
    IconComponent,
    HMapChart,
    waveSvg,
  },
  props: {
    widget: {
      required: true,
      type: Object,
    },
    showOptionsPanel: {
      type: Boolean,
      default: false,
    },
    refreshData: {
      type: Boolean,
      default: true,
    },
    widgetName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    showOptsPanel: false,
    loadedData: null,
    total: 0,
    loading: false,
    params: null,
    modelOptions: null,
    filters: {},
    chartConf: {},
    availableFilters: [],
    filtersArray: [],
    fields: [],
    fieldsOptions: [],
    colors: [],
    selectedChartValue: null,
    intervalId: null,
    appliedFilters: null,
    topBarVisible: false,
    controller: null,
    signal: null,
  }),
  created() {
    if (this.widget.id) {
      if (this.widget.filters && !Array.isArray(this.widget.filters)) {
        this.filters = this.widget.filters;
      } else {
        this.filters = {};
      }

      if (this.widget.chartConf) {
        this.chartConf = JSON.parse(JSON.stringify(this.widget.chartConf));
      } else {
        this.chartConf = {};
      }
    }

    if (JSON.stringify(this.filters) === "{}") this.setDefaultFilters();
  },
  async mounted() {
    const data = await this.loadData();
    this.loadedData = data.values;
    this.total = this.validateTotal(data.meta.total, this.loadedData);
    const modelOptions = await this.fetchModelOptions(this.widget.model);
    this.modelOptions = modelOptions.data.data;
    this.fields = this.modelOptions.fields;
    this.availableFilters = this.modelOptions.filters;
    this.topBarVisible = true;
    this.$emit("loading", false);

    if (this.widget.field !== 'density') {
      this.intervalId = setInterval(() => {
        if (this.showOptsPanel || this.loading || !this.$refs.chartContainer.checkVisibility()) return;
        this.applyFilters();
      }, 30 * 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  watch: {
    showOptionsPanel(value) {
      this.showOptsPanel = value;
    },
    showOptsPanel(value) {
      this.$emit("update:showOptionsPanel", value);
    },
    refreshData(value) {
      if (value) {
        this.intervalId = setInterval(() => {
          if (this.showOptsPanel || this.loading) return;
          this.applyFilters();
        }, 60 * 1000);
      } else {
        clearInterval(this.intervalId);
      }
    },
    darkMode() {
      this.loadData();
    },
  },
  methods: {
    fetchModelOptions(model, loadOptions = false) {
      return axios.get("charts-api/get-model-options", {
        params: {model, load_options: loadOptions},
      });
    },
    loadData() {
      this.controller = new AbortController();
      this.signal = this.controller.signal;

      return new Promise(async (resolve) => {
        this.loading = true;
        this.$emit("loading", true);
        this.params = {
          model: this.widget.model,
          field: this.widget.field,
          filters: this.activeFilters,
          chart: this.widget.chart,
          chartConf: this.chartConf,
        };

        const {data} = await axios.get("charts-api/get-stats", {
          signal: this.signal,
          params: this.params,
        });

        this.loadedData = data.data.values;
        const values = Object.values(this.loadedData);
        this.total = this.validateTotal(data.data.meta.total, this.loadedData);

        // this.colors =
        //   values.length && values[0].color
        //     ? values.map((v) => v.color)
        //     : this.generateColors(Object.keys(this.loadedData).length);

        const requiredColors = values.length;
        this.colors = this.generateColors(requiredColors);

        this.loading = false;
        this.$emit("loading", false);
        await this.$store.dispatch("setFilters", this.activeFilters);
        await this.$store.dispatch("setChartConf", this.chartConf);
        this.$emit("updated-config");

        resolve(data.data);
      });
    },
    validateTotal(total, loadedData) {
      // const values = Object.values(loadedData);
      // if (values.length === 1 && !values[0].option || !values.length) {
      //     return 0;
      // }
      return total;
    },
    async loadFieldOptions() {
      return new Promise((resolve) => {
        this.$emit("loading", true);
        this.fetchModelOptions(this.widget.model, true).then((resp) => {
          resolve();
        });
      });
    },
    setDefaultFilters() {
      const {start, end} = helpers.buildDateRangeFromWildcard('month');

      this.filters.from = start;
      this.filters.to = end;

      this.chartConf = {
        dateGroupFieldWildcard: 'day',
        dateFilterWildcard: 'month'
      }
    },
    removeFilters() {
      let bkpFilters = {};
      if (this.keepFilters.length > 0) {
        this.keepFilters.forEach((filter) => {
          if (this.filters[filter]) {
            bkpFilters[filter] = this.filters[filter];
          }
        });
      }

      this.filters = Object.assign({}, bkpFilters);
      this.setDefaultFilters();
      this.applyFilters();
    },
    async applyFilters() {
      this.$root.$el.dispatchEvent(
          new CustomEvent("filters-updated", {
            detail: {data: JSON.parse(JSON.stringify(this.params))},
          })
      );
      // this.appliedFilters = this.resolveMetadataFilters();

      this.controller.abort();
      await this.loadData();
    },
    shareData() {
      this.$root.$el.dispatchEvent(
          new CustomEvent("share-data", {
            detail: {data: JSON.parse(JSON.stringify(this.params))},
          })
      );
    },
    onChartClicked(chart, data) {
      if (data && data.length) {
        const index = data[0].index;
        const field = this.widget.field;
        const selectedField = this.fields.find((f) => f.value === field);
        const chartLabel = this.chartLabels[index];

        if (field === "created") {
          this.selectedChartValue = {
            field,
            type: selectedField?.type,
            label: field,
            value: chartLabel,
          };
        } else {
          const fieldOptions = selectedField.options;
          const optionsLabels = Object.values(fieldOptions);
          const optionsValues = Object.keys(fieldOptions);
          const selectedLabelIndex = optionsLabels.findIndex(
              (label) => label === chartLabel
          );

          this.selectedChartValue = {
            field,
            type: selectedField?.type,
            label: optionsLabels[selectedLabelIndex],
            value: optionsValues[selectedLabelIndex],
          };
        }
      }
    },
    resolveMetadataFilters() {
      if (this.filters.hasOwnProperty("data")) {
        const filters = this.filters.data;
        const formattedFilters = filters.map((f) => {
          const chunks = decodeURIComponent(f).split(",");
          const field = chunks[0];
          const valueChunks = chunks[1].split("|");
          const value =
              valueChunks.length > 1 ? valueChunks[1] : valueChunks[0];
          const operator = valueChunks.length > 1 ? valueChunks[0] : null;

          const resolveOperatorSymbol = (op) => {
            switch (op) {
              case "equal_to":
                return "=";
              case "contains":
                return "≈";
              case "not_contains":
                return "!=";
              case "gt":
                return ">";
              case "lt":
                return "<";
              case "gte":
                return ">=";
              case "lte":
                return "<=";
              case "is_in":
                return " in ";
              case "is_not_in":
                return "not in";
              default:
                return "=";
            }
          };

          return `${this.$t(field)} ${resolveOperatorSymbol(operator)} ${
              isNaN(value) ? this.$t(value) : value
          }`;
        });

        return formattedFilters.join(", ");
      }

      return null;
    },
    shouldShowClearBtn() {
      const filterKeys = Object.keys(this.filters);
      if (filterKeys.length === 1) {
        for (let index = 0; index < filterKeys.length; index++) {
          const filter = filterKeys[index];
          if (this.keepFilters.includes(filter)) return false;
        }
      }

      return JSON.stringify(this.filters) !== "{}";
    },
  },
  computed: {
    currentField() {
      const field = this.fields.find((f) => f.value === this.widget.field);

      if (!field) {
        return "";
      }

      return field;
    },
    activeFilters() {
      return this.filters;
    },
    keepFilters() {
      return this.$store.state.config.keep_filters;
    },
    totalFormatted() {
      if (!this.total) return 0;
      return new Intl.NumberFormat("en-US").format(this.total);
    },
    darkMode() {
      return this.$store.state.config.darkMode;
    },
    viewportHeight() {
      const heightV = this.$store.getters.viewport.height;
      return heightV - 70;
    },
    withTotal() {
      const widthV = this.$store.getters.viewport.width;
      const widthMap = {
        2560: 99,
        1920: 98,
        1700: 97,
        1500: 96,
        1280: 94,
        950: 92,
        720: 90,
        600: 88,
        500: 86,
        400: 82,
        300: 80,
        0: 88,
      };

      const percentage = Object.keys(widthMap)
          .reverse()
          .find((key) => widthV > key);
      const finalPercentage = widthMap[percentage];

      console.log(finalPercentage, "percentage");
      return finalPercentage;
    },
    chartType() {
      switch (this.widget.chart) {
        case "bar":
          return "bar-chart";
        case "pie":
          return "pie-chart";
        case "map":
          return "h-map-chart";
        default:
          return "bar-chart";
      }
    },
    chartLabels() {
      return Object.keys(this.loadedData).map((label) =>
          label === "" ? "No name" : label
      );
    },
    chartOptions() {
      if (this.widget.chart === "pie") {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              align: "middle",
              labels: {
                color: this.darkMode ? "#fff" : "#000",
              },
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  if (!context.dataset.percentage) {
                    const data = new Intl.NumberFormat("en-US").format(
                        context.parsed
                    );
                    return ` ${data}`;
                  }

                  let label = context.dataset.label || "";

                  if (context.parsed.y !== null) {
                    label += context.parsed + "%";
                  }

                  return label;
                },
              },
            },
          },
          onClick: (_, data) => this.onChartClicked("pie", data),
        };
      }

      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              color: this.darkMode ? "#fff" : "#555B64",
            },
            grid: {
              color: "#708599",
            },
          },
          y: {
            ticks: {
              beginAtZero: false,
              color: this.darkMode ? "#fff" : "#555B64",
            },
            grid: {
              color: "#708599",
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: this.darkMode ? "#fff" : "#555B64",
            },
            onClick: false,
          },
          tooltip: {
            intersect: false,
            mode: "index",
            callbacks: {
              label: function (context) {
                if (!context.dataset.percentage) {
                  const data = new Intl.NumberFormat("en-US").format(
                      context.parsed.y
                  );
                  return ` ${data}`;
                }

                let label = context.dataset.label || "";

                if (context.parsed.y !== null) {
                  label = context.parsed.y + "%";
                }

                return label;
              },
            },
          },
        },
        onClick: (_, data) => this.onChartClicked("bar", data),
      };
    },
    chartDataValues() {
      return this.chartLabels.map((label) => {
        if (label === "No name") {
          return this.loadedData[""].count;
        }
        return this.loadedData[label].count;
      });
    },
    chartData() {
      if (!this.loadedData) return;

      if (this.widget.chart === "pie") {
        return {
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.chartDataValues,
              percentage: this.currentField.percentage,
            },
          ],
        };
      }

      return {
        labels: this.chartLabels,
        datasets: [
          {
            label: this.currentField.text,
            backgroundColor: this.colors,
            data: this.chartDataValues,
            percentage: this.currentField.percentage,
            borderRadius: 15,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.omvcsdk .col-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.chart-legend {
  display: flex;
  align-items: center;
}
</style>
