export default {
    namespaced: false,
    metadata: {
        type: 'Tipo',
        gender: 'Género',
        female: 'Femenino',
        male: 'Masculino',
        color: 'Color',
        confidence: 'Confianza',
        plate: 'Placa',
        brand: 'Marca',
        model: 'Modelo',
        class: 'Clase',
        person: 'Persona',
        car: 'Vehículo'
    }
}
