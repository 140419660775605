<template>
    <div class="d-flex align-items-center w-100 mb-1">
      <select v-model="selectedOperator" class="form-control-input-select w-75 mr-1">
          <option v-for="(operator, index) in operators" :key="index" :value="operator.value">
              {{ operator.text }}
          </option>
      </select>      
      <v-select
        v-if="list && !loadingList"
        v-model="model"
        :options="list"
        :multiple="['is_in', 'is_not_in'].includes(selectedOperator)"
        :append-to-body="true"
        :calculate-position="withPopper"
        :disabled="loadingList"
        class="w-100 form-control-input-select"
      />
      <v-select
        v-else-if="!list && ['is_in', 'is_not_in'].includes(selectedOperator)"
        v-model="model"
        taggable
        multiple
        :append-to-body="true"
        :calculate-position="withPopper"
        :disabled="loadingList"
        class="w-100 form-control-input-select"
      />
      <div v-else-if="loadingList" class="text-center" :style="`width: ${fieldWidth};`">
        Loading...
      </div>
      <input
        v-else
        v-model="model"
        type="text"
        class="form-control-input-select"
        :style="`width: ${fieldWidth};`"
      />
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import vSelect from "vue-select";
  import { createPopper } from "@popperjs/core";
  import { toQueryString } from './helpers';

  export default {
    components: {
      vSelect,
    },
    props: {
      type: String,
      value: Object,
      config: Object,
      extFilters: Object,
    },
    mounted() {
      if (this.isObject(this.value)) {
        this.model = this.value.value;
        this.selectedOperator = this.value.operator;
      } else {
        this.model = this.value;
      }
  
      if (this.config && this.config.list) {
        this.hasList = true;
        this.setList();
      } else {
        this.loadingList = false;
      }
    },
    data() {
      return {
        model: null,
        selectedOperator: "contains",
        fieldWidth: "260px",
        operators: [
          {
            value: "equal_to",
            text: this.$t("optionsPanel.metadataFilter.operators.equal_to"),
          },
          {
            value: "not_equal_to",
            text: this.$t("optionsPanel.metadataFilter.operators.not_equal_to"),
          },
          {
            value: "contains",
            text: this.$t("optionsPanel.metadataFilter.operators.contains"),
          },
          {
            value: "not_contains",
            text: this.$t("optionsPanel.metadataFilter.operators.not_contains"),
          },
          { value: "gt", text: this.$t("optionsPanel.metadataFilter.operators.gt") },
          { value: "lt", text: this.$t("optionsPanel.metadataFilter.operators.lt") },
          { value: "gte", text: this.$t("optionsPanel.metadataFilter.operators.gte") },
          { value: "lte", text: this.$t("optionsPanel.metadataFilter.operators.lte") },
          { value: "is_in", text: this.$t("optionsPanel.metadataFilter.operators.is_in") },
          {
            value: "is_not_in",
            text: this.$t("optionsPanel.metadataFilter.operators.is_not_in"),
          },
        ],
        hasList: false,
        list: null,
        loadingList: true,
      };
    },
    watch: {
      model(data) {
        if (data && (Array.isArray(data) ? data.length > 0 : true)) {
          this.$emit("input", { value: data, operator: this.selectedOperator });
        } else {
          this.$emit("input", null);
        }
      },
      selectedOperator(operator) {
        this.$emit("input", {
          value: this.model || null,
          operator,
        });
      },
    },
    methods: {
      isObject(value) {
        return typeof value === "object" && value !== null && !Array.isArray(value);
      },
      setList() {
        if (this.config.list.constructor === Array) {
          this.list = this.config.list;
          this.loadingList = false;
        } else if (this.config.list.constructor === String) {
          this.fetchList();
        }
      },
      fetchList() {
        let url = `${this.$store.state.config.eventMsUrl}/field-values?${this.config.list}`;
  
        if (this.extFilters.customFields) {
          url += `&${toQueryString(this.extFilters.customFields)}`;
        }
  
        axios.get(url).then(({ data }) => {
          const resp = data.data;
          if (resp && resp.length > 0) {
            this.list = resp.map((item) => ({ value: item, label: item }));
          }
          this.loadingList = false;
        });
      },
      withPopper(dropdownList, component, { width }) {
        dropdownList.style.width = width;
        dropdownList.style.zIndex = (500000).toString();
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: "bottom",
        });
        return () => popper.destroy();
      },
    },
  };
  </script>
  
  <style></style>
  