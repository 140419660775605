<template>
    <div>
      <v-select
        :options="newCamerasOptions"
        @search="onSearch"
        :multiple="multiple"
        :closeOnSelect="!multiple"
        label="label"
        @input="updateSelected"
        :clearable="true"
        v-model="inputCameraId"
      >
        <template #no-options>
          <div class="custom-no-options">
            {{ $t("installation_components.no_options_found") }}
          </div>
        </template>
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>
        <li slot="list-footer" class="pagination">
          <button :disabled="hasPrevPage" @click="antCameras()">
            {{ $t("installation_components.previous") }}
          </button>
          <button :disabled="hasNextPage" @click="sigCameras()">
            {{ $t("installation_components.following") }}
          </button>
        </li>
      </v-select>
    </div>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import axios from "@axios";
  
  export default {
    components: {
      vSelect,
    },
    props: {
      multiple: {
        type: Boolean,
        required: true,
      },
      id_camera: {
        type: [Number, Array, Object],
        default: null,
      },
      id_unit: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        inputCameraId: null,
        search: "",
        offset: 0,
        limit: 10,
        newCamerasOptions: [],
        page: 1,
        lastPageCameras: 0,
        hasNextPage: false,
        hasPrevPage: true,
        url: `/proxy_camera`,
      };
    },
    methods: {
      updateSelected(val) {
        if (val !== null) {
          if (this.multiple) {
            const array = val.map((element) => ({
              label: element.label,
              value: element.value,
            }));
            this.$emit("update:id_camera", array);
          } else {
            this.$emit("update:id_camera", {
              value: val.value,
              label: val.label,
            });
          }
        } else {
          this.inputCameraId = null;
          this.$emit("update:id_camera", this.inputCameraId);
        }
      },
      onSearch(query) {
        this.page = 1;
        this.hasNextPage = false;
        this.hasPrevPage = true;
        if (query === " ") {
          this.fetchCameras({
            max: 10,
            "page[n]": this.page,
            "units[]": this.id_unit,
          });
        } else {
          this.search = query;
          this.fetchCameras({
            max: 10,
            "page[n]": this.page,
            q: query,
            "units[]": this.id_unit,
          });
        }
      },
      fetchCameras(params) {
        axios
          .get(this.url, { params })
          .then((response) => {
            const items = response.data.data;
            this.newCamerasOptions = items.map((item) => ({
              value: item.id,
              label: item.camera.name,
            }));
            this.lastPageCameras = response.data.meta.last_page;
            this.hasPrevPage = this.page === 1;
            this.hasNextPage = this.page === this.lastPageCameras;
          })
          .catch((error) => {
            console.error(error);
          });
      },
      sigCameras() {
        if (!this.hasNextPage) return;
        this.page++;
        const params = {
          max: 10,
          "page[n]": this.page,
          q: this.search,
          "units[]": this.id_unit,
        };
        this.fetchCameras(params);
      },
      antCameras() {
        if (!this.hasPrevPage) return;
        this.page--;
        const params = {
          max: 10,
          "page[n]": this.page,
          q: this.search,
          "units[]": this.id_unit,
        };
        this.fetchCameras(params);
      },
    },
    mounted() {
      this.inputCameraId = this.id_camera;
      this.fetchCameras({
        max: 10,
        "page[n]": this.page,
        "units[]": this.id_unit,
      });
    },
    watch: {
      id_camera(newValue) {
        if (newValue) {
          if (this.multiple) {
            this.inputCameraId = newValue.map((element) => ({
              label: element.label,
              value: element.value,
            }));
          } else {
            this.inputCameraId = {
              value: newValue.value,
              label: newValue.label,
            };
          }
        } else {
          this.inputCameraId = null;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .pagination {
    display: flex;
    margin: 0.25rem 0.25rem 0;
  }
  
  .pagination button {
    flex-grow: 1;
    background: none;
    border: 1px solid #ccc;
    padding: 0.5rem;
  }
  
  .pagination button:hover {
    cursor: pointer;
    background-color: #f0f0f0;
  }
  </style>
  