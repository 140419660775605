<template>
    <div ref="map" :style="`width: ${mapWidth}; height: ${mapHeight}`"></div>
</template>

<script>
import { loaded } from './loader';
import styles from './darkModeStyles.json';

export default {
    name: 'g-maps',
    props: {
        lat: {
            type: Number,
            default: () => 37.775,
        },
        lng: {
            type: Number,
            default: () => -122.434,
        },
        initialZoom: {
            type: Number,
            default: () => 13,
        },
        mapType: {
            type: String,
            default: () => 'roadmap',
        },
        points: {
            type: Array,
            required: true,
        },
        width: {
            type: [String, Number],
            default: () => '100%',
        },
        height: {
            type: [String, Number],
            default: () => '100%',
        },
        options: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        mapWidth() {
            if (typeof this.width === 'string') {
                return this.width;
            } else {
                return `${this.width}px`;
            }
        },
        mapHeight() {
            if (typeof this.height === 'string') {
                return this.height;
            } else {
                return `${this.height}px`;
            }
        },
        heatmapPoints() {
            return this.points.map((point) => {
                return { location: new google.maps.LatLng(point.lat, point.lng), weight: point.weight };
            });
        },
        darkMode() {
            return this.$store.state.config.darkMode;
        },
    },
    watch: {
        darkMode(value) {
            if (value) {
                this.$mapObject.setOptions({ styles });
            } else {
                this.$mapObject.setOptions({ styles: null });
            }
        },
        heatmapPoints(points) {
            this.$heatmap.setData(points);
        },
        options: {
            handler(options) {
                this.$heatmap.setOptions(options);
            },
            deep: true,
        },
    },
    created() {
        return loaded.then(() => {
            const mapElement = this.$refs.map;

            this.$mapObject = new google.maps.Map(mapElement, {
                zoom: this.initialZoom,
                center: { lat: this.lat, lng: this.lng },
                mapTypeId: this.mapType,
            });

            if (this.darkMode) {
                this.$mapObject.setOptions({ styles });
            }

            this.$heatmap = new google.maps.visualization.HeatmapLayer({
                data: this.heatmapPoints,
                map: this.$mapObject,
            });

            this.$heatmap.setMap(this.$mapObject);
            this.$heatmap.setOptions(this.options);
        });
    },
};
</script>
