const langs = ["en", "es", "pt"];
const files = [
  "base",
  "metadata",
];

const messages = {};

for (const lang of langs) {
  for (const fileName of files) {
    const {default: file} = require(`./${lang}/${fileName}`);
    const fileContent = file.namespaced ? file : file[fileName];
    if (messages[lang]) {
      messages[lang] = Object.assign(messages[lang], {...fileContent});
    } else {
      messages[lang] = {};
      messages[lang] = Object.assign(messages[lang], {...fileContent});
    }
  }
}

export default messages;
