<template>
  <div class="options-panel p-2" :class="{ show: show }">
    <div class="d-flex justify-content-end mb-3">
      <a
          href="#"
          class="float-right"
          @click.prevent="$emit('update:show', !show)"
      >
        <icon-component icon="times-icon" size="20"/>
      </a>
    </div>

    <!-- Show "Fields" and "Chart" field only when selected chart is different to "Total" -->
    <template v-if="!['total'].includes(localWidget.chart)">
      <div class="mb-3">
        <p class="lead">{{ $t("optionsPanel.fields") }}</p>
        <select
            id="field"
            class="form-control form-control-sm form-control-input"
            v-model="localWidget.field"
        >
          <option disabled>{{ $t("optionsPanel.fields") }}</option>
          <template v-for="(field, index) in fields">
            <option
                v-if="field.visible_in !== 'filters'"
                :key="index"
                :value="field.value"
                :selected="localWidget.field === field.value"
            >
              {{ field.text }}
            </option>
          </template>
        </select>
      </div>
      <div class="mb-3" v-if="selectedField.type === 'date'">
        <date-wildcards
            type="groupField"
            v-model="localChartConf.dateGroupFieldWildcard"
        />
      </div>
      <div class="mb-4" v-if="selectedField">
        <p class="lead">{{ $t("optionsPanel.chart") }}</p>
        <select
            id="chart"
            name="chart"
            class="form-control form-control-sm form-control-input"
            v-model="localWidget.chart"
        >
          <option
              value="bar"
              selected
              v-if="selectedField.charts.includes('bar')"
          >
            {{ $t("optionsPanel.charts.bar") }}
          </option>
          <option value="pie" v-if="selectedField.charts.includes('pie')">
            {{ $t("optionsPanel.charts.doughnut") }}
          </option>
          <option
              value="map"
              selected
              v-if="selectedField.charts.includes('map')"
          >
            {{ $t("optionsPanel.charts.map") }}
          </option>

          <template v-if="selectedField.value === 'density'">
            <option
                value="total_people"
                selected
                v-if="selectedField.charts.includes('total_people')"
            >
              Total people
            </option>
            <option
                value="avg_stay"
                selected
                v-if="selectedField.charts.includes('avg_stay')"
            >
              Average stay
            </option>
          </template>
        </select>
      </div>
    </template>

    <div>
      <p class="lead">{{ $t("optionsPanel.filters") }}</p>
      <table class="table table-bordered" v-if="modelOptions">
        <template v-for="(field, index) in modelOptions.fields">
          <tr
              v-if="modelOptions.filters.includes(field.value) && field.visible_in !== 'fields'"
              :key="index"
          >
            <td style="width: 33.3%">{{ field.text }}</td>
            <td>
              <span class="d-block text-center lead">=</span>
            </td>
            <td>
              <v-select
                  v-if="field.type === 'select' || field.type === 'multiple'"
                  v-model="localFilters[field.value]"
                  :options="parseOptions(field.options)"
                  :clearable="true"
                  :multiple="field.type === 'multiple'"
                  :append-to-body="true"
                  :reduce="(val) => val.value"
                  :calculate-position="withPopper"
                  @input="onInput($event, field.value)"
                  class="charts-sdk-select"
              />
              <template v-if="field.type === 'date'">
                <div class="d-flex justify-content-around">
                  <div class="d-flex flex-column w-100">
                    <date-wildcards
                        v-model="localChartConf.dateFilterWildcard"
                        @option:selected="onSelectedDateFilterWildCard"/>

                    <div
                        class="d-flex flex-column"
                        v-if="localChartConf.dateFilterWildcard === 'custom'"
                    >
                      <!-- from -->
                      <div class="d-flex mb-3">
                        <div class="d-flex align-items-center justify-content-center p-0">
                          {{ $t("optionsPanel.dates.from") }}
                        </div>
                        <div class="col custom-datetime-icon">
                          <input
                              type="datetime-local"
                              class="form-control form-control-sm d-inline datetime-local"
                              ref="date_from"
                              @change="onDateChange"
                          />
                        </div>
                      </div>
                      <!-- to -->
                      <div class="d-flex">
                        <div
                            class="d-flex align-items-center justify-content-center p-0 mx-2"
                        >
                          {{ $t("optionsPanel.dates.to") }}
                        </div>
                        <div class="col custom-datetime-icon">
                          <input
                              type="datetime-local"
                              class="form-control form-control-sm custom-datetime-local"
                              ref="date_to"
                              @change="onDateChange"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- clear -->
                  <div class="d-flex align-items-center p-0">
                    <a
                        href="#"
                        class="text-danger"
                        @click.prevent="clearDateFilter"
                    >
                      <icon-component
                          icon="trash-icon"
                          size="12"
                          color="#dc3545"
                      />
                    </a>
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </template>
        <detection-events-filter
            v-if="modelOptions.value === 'DetectionEvent'"
            :model-data="modelOptions"
            :value="filters"
        />
      </table>
    </div>
    <div class="d-flex justify-content-end p-3">
      <button class="btn btn-md btn-button-apply" @click="btnApplyClick">
        <span class="text-white">
          {{ $t("optionsPanel.apply") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import IconComponent from "../icons/icon-component.vue";
import {createPopper} from "@popperjs/core";
import DetectionEventsFilter from "./DetectionEventsFilter/DetectionEventsFilter.vue";
import DateWildcards from "./partials/DateWildcards.vue";
import helpers from '@/libs/helpers';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
    },
    chartConf: {
      type: Object,
      default: () => ({
        dateGroupFieldWildcard: 'day',
        dateFilterWildcard: 'month',
      })
    },
    fields: {
      type: Array,
      default: [],
    },
    modelOptions: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    selectedChartValue: {
      type: Object,
    },
    keepFilters: {
      type: Array,
      default: [],
    },
  },
  components: {
    DateWildcards,
    IconComponent,
    DetectionEventsFilter,
  },
  data() {
    return {
      localChartConf: JSON.parse(JSON.stringify(this.chartConf)),
      localWidget: JSON.parse(JSON.stringify(this.widget)),
      localFilters: {},
    };
  },
  mounted() {
    if (JSON.stringify(this.chartConf) === "{}") {
      this.localChartConf = {
        dateGroupFieldWildcard: 'day',
        dateFilterWildcard: 'month',
      };
    }
  },
  watch: {
    widget: {
      handler(newVal) {
        this.localWidget = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
    chartConf: {
      handler(newVal) {
        this.localChartConf = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
    },
    filters(val) {
      const clearFilters = () => {
        this.localFilters = {};
        this.$refs.date_from[0].value = null;
        this.$refs.date_to[0].value = null;
      };

      if (this.keepFilters.length > 0 && JSON.stringify(val) !== "{}") {
        clearFilters();
      } else if (JSON.stringify(val) === "{}") {
        clearFilters();
      }
    },
    selectedChartValue(val) {
      this.filters[val.field] = [val.value];
      this.$emit("update:filters", this.filters);
      this.$emit("apply-filters");
      this.$nextTick(() => {
        this.localFilters[val.field] =
            val.type === "multiple"
                ? [{label: val.label, value: val.value}]
                : {label: val.label, value: val.value};
      });
    },
    modelOptions() {
      this.localFilters = JSON.parse(JSON.stringify(this.filters));

      this.$nextTick(() => {
        if (this.filters.from && this.filters.to) {
          this.$refs.date_from[0].value = this.filters.from;
          this.$refs.date_to[0].value = this.filters.to;
        }
      });
    },
  },
  computed: {
    selectedField() {
      if (!this.localWidget.field) return null;

      return this.fields.find(
          (field) => field.value === this.localWidget.field
      );
    },
  },
  methods: {
    parseOptions(options) {
      return Object.entries(options).map((obj) => ({
        label: obj[1],
        value: obj[0],
      }));
    },
    setFilterOptions(filter, options) {
      let opts = [];

      if (options) {
        opts = Object.keys(options).map((k) => ({
          value: options[k],
          id: k,
          filter,
        }));
      }

      return opts;
    },
    onInput(values, filter) {
      let data = null;

      if (!values || (Array.isArray(values) && !values.length)) {
        delete this.filters[filter];
      } else {
        this.$nextTick(() => {
          data = Array.isArray(values) ? values : [values];

          if (data && data.length) {
            this.filters[filter] = data;
          }
        });
      }
    },
    onDateChange() {
      const from = this.$refs.date_from[0].value;
      const to = this.$refs.date_to[0].value;

      this.filters["from"] = from;
      this.filters["to"] = to;
    },
    clearDateFilter() {
      const {start, end} = helpers.buildDateRangeFromWildcard('month');

      this.filters["from"] = start;
      this.filters["to"] = end;

      this.localChartConf = {
        dateGroupFieldWildcard: 'day',
        dateFilterWildcard: 'month'
      };
    },
    withPopper(dropdownList, component, {width}) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "top",
      });
      return () => popper.destroy();
    },
    onSelectedDateFilterWildCard(option) {
      const {start, end} = helpers.buildDateRangeFromWildcard(option);

      this.filters["from"] = start;
      this.filters["to"] = end;
    },
    btnApplyClick() {
      this.$nextTick(() => {
        this.$store.dispatch('setConfig', {field: this.localWidget.field})
        this.$store.dispatch("setConfig", {chart: this.localWidget.chart});
        this.$emit('update:chartConf', this.localChartConf);
        this.$emit("update:filters", this.filters);
        this.$emit("apply-filters");
        this.$emit('update:show', !this.show)
      });
    },
  },
};
</script>

<style scoped>
.charts-sdk-select .vs__deselect {
  fill: #e5e5e5;
  margin-left: 5px;
}

.charts-sdk-select .vs__selected {
  color: #fff !important;
  background-color: #1b3d5e !important;
}

.dark-layout
.custom-datetime-icon
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}
</style>
