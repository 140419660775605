<template>
  <div>
    <v-select
      :options="newUnitsOptions"
      @search="onSearch"
      :multiple="multiple"
      :closeOnSelect="!multiple"
      label="label"
      @input="updateSelected"
      :clearable="true"
      v-model="inputUnitId"
    >
      <template #no-options>
        <div class="custom-no-options">
          {{ $t("installation_components.no_options_found") }}
        </div>
      </template>
      <template #search="{ attributes, events }">
        <input class="vs__search" v-bind="attributes" v-on="events" />
      </template>
      <li slot="list-footer" class="pagination">
        <button :disabled="!hasPrevPage" @click="antUnits()">
          {{ $t("installation_components.previous") }}
        </button>
        <button :disabled="!hasNextPage" @click="sigUnits()">
          {{ $t("installation_components.following") }}
        </button>
      </li>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "@axios";

export default {
  components: {
    vSelect,
  },
  props: {
    multiple: {
      type: Boolean,
      required: true,
    },
    id_unit: {
      type: [Number, Array, Object],
      default: null,
    },
  },
  data() {
    return {
      inputUnitId: null,
      search: "",
      limit: 10,
      newUnitsOptions: [],
      page: 1,
      lastPageUnits: 0,
      hasNextPage: false,
      hasPrevPage: true,
      url: `/unit`,
    };
  },
  methods: {
    updateSelected(val) {
      if (val !== null) {
        if (this.multiple) {
          let array = [];
          val.forEach((element) => {
            array.push({ label: element.label, value: element.value });
          });
          this.$emit("update:id_unit", array);
        } else {
          this.$emit("update:id_unit", { value: val.value, label: val.label });
        }
      } else {
        this.inputUnitId = null;
        this.$emit("update:id_unit", this.inputUnitId);
      }
    },
    onSearch(query) {
      this.page = 1;
      this.search = query.trim() ? query : "";
      this.fetchUnits();
    },
    fetchUnits() {
      const params = {
        max: this.limit,
        "page[n]": this.page,
        q: this.search,
      };
      
      axios
        .get(this.url, { params })
        .then((response) => {
          let items = response.data.data;
          this.newUnitsOptions = items.map((item) => {
            return {
              value: item.id,
              label: item.description,
            };
          });
          this.lastPageUnits = response.data.meta.last_page;
          this.hasPrevPage = this.page === 1;
          this.hasNextPage = this.page === this.lastPageUnits;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sigUnits() {
      if (!this.hasNextPage) return;
      this.page++;
      this.fetchUnits();
    },
    antUnits() {
      if (!this.hasPrevPage) return;
      this.page--;
      this.fetchUnits();
    },
  },
  mounted() {
    this.inputUnitId = this.id_unit;
    this.fetchUnits();
  },
  watch: {
    id_unit(newValue) {
      if (newValue) {
        if (Array.isArray(newValue)) {
          this.inputUnitId = newValue.map(unit => ({
            value: unit.value,
            label: unit.label,
          }));
        } else {
          this.inputUnitId = {
            value: newValue.value,
            label: newValue.label,
          };
        }
      } else {
        this.inputUnitId = null;
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
  background: none;
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.pagination button:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
</style>
