<template>
    <div class="row">
        <div class="col">
            <label for="">De</label>
            <input
                v-model="fromDate"
                type="date"
                :max="maxDate"
                class="form-control form-control-sm"
                @input="changeDate"
            />
        </div>
        <div class="col">
            <label for="">A</label>
            <input
                v-model="toDate"
                type="date"
                :max="maxDate"
                class="form-control form-control-sm"
                @input="changeDate"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    components: {},
    props: {
        from: String,
        to: String,
    },
    data: () => ({
        fromDate: null,
        toDate: null,
    }),
    mounted() {
        this.init();
    },
    methods: {
        changeDate() {
            // this.$store.commit('setDateRange', { fromDate: this.fromDate, toDate: this.toDate });
            this.$emit('change', { fromDate: this.fromDate, toDate: this.toDate });
        },
        init() {
            this.fromDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
            this.toDate = moment().format('YYYY-MM-DD');
            this.changeDate();
        },
    },
    computed: {
        maxDate() {
            return moment().format('YYYY-MM-DD');
        },
    },
};
</script>
