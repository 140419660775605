import axios from "@axios";
import moment from "moment";

const swiftSensorsApi = 'https://';

export default {

    getSensorData(sensorId, dates = {}, minutes = 5) {

        const start = dates.from ? moment(dates.from).utc().unix() : moment().subtract(30, 'minutes').utc().unix();
        const end = dates.to ? moment(dates.to).utc().unix() : moment().utc().unix();

        const params = {
            start,
            end,
            "minutes": minutes,
            "sensor_id": sensorId,
        };

        return axios.get('/services/sensors/get-sensor-data', { params });
    }

}