<template>
  <div class="widget">
    <div class="header-container">
      <div class="header-item-title">
        <h3 class="widget-title-input">
          <input
              id="widget_name"
              type="text"
              v-model="widgetName"
              class="widget-input"
          />
        </h3>
      </div>
      <div class="header-item">
        <!--
        <button id="btn_save" class="btn-save" @click="onBtnSaveClick">
          <icon-component icon="save-icon" size="20" />
        </button>
        -->
        <a
            v-if="data.field !== 'density'"
            href="#"
            class="options-link"
            @click.prevent="showOptionsPanel = !showOptionsPanel"
        >
          <icon-component icon="gear-icon" size="20" :class="{'spinner_aj0A': loading}"/>
        </a>
      </div>
    </div>

    <!-- <div 
                v-if="!$store.state.sensorsView" 
                id="btn_live" 
                class="float-right" 
                :title="$t('app.refreshInterval')">
                <toggle-button 
                    :value="refreshData"
                    :sync="true"
                    @change="(value) => refreshData = !refreshData"
                    color="#28c76f"
                    :labels="true" 
                    />
            </div> -->

    <div class="spinner" v-if="loading && !refreshData">
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path
            d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
            class="spinner_aj0A"
        />
      </svg>
    </div>

    <component
        :is="chartType"
        :widget="data"
        :show-options-panel.sync="showOptionsPanel"
        :refresh-data="refreshData"
        @loading="showLoading"
        @updated-config="dispatchConfigUpdatedEvent"
    />
  </div>
</template>

<script>
import axios from "@axios";
import DailyCount from "./charts/DailyCount.vue";
import GroupCount from "./charts/GroupCount.vue";
import IconComponent from "./icons/icon-component.vue";
import HeatImageMap from './charts/HeatImageMap.vue';
import debounce from "lodash.debounce";
import {ToggleButton} from "vue-js-toggle-button";

export default {
  components: {DailyCount, GroupCount, HeatImageMap, IconComponent, ToggleButton},
  name: "Widget",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      modelConfig: null,
      showOptionsPanel: false,
      loading: false,
      refreshData: true,
    };
  },
  mounted() {
    if (!this.data.id && !this.config.name) {
      this.widgetName = `${this.data.name} (${this.data.model})`;
    }
  },
  watch: {
    config: {
      handler() {
        this.dispatchConfigUpdatedEvent();
      },
      deep: true,
    },
  },
  computed: {
    config() {
      return this.$store.getters["config"];
    },
    widgetName: {
      get() {
        return this.$store.state.config.name;
      },
      set(val) {
        this.$store.dispatch("setConfig", {name: val});
      },
    },
    chartType() {
      switch (this.data.chart) {
        case "bar":
        case "pie":
        case "map":
        case "total":
        case "total_people":
        case "avg_stay":
          return "group-count";
        case "line":
          return "daily-count";
        case 'image_heat_map':
          return 'heat-image-map';
      }
    },
  },
  methods: {
    onBtnSaveClick() {
      const chartId = this.$store.state.config.id;
      const data = this.$store.state.config;
      let request = null;
      let action = "";
      this.loading = true;
      //console.log('data Btn SaveClick', data);

      if (!chartId) {
        action = "create";
        request = axios
            .post("/services/user-charts", {data})
            .then(({data}) => {
              const id = data.data.id;
              this.$store.dispatch("setConfig", {id});
            });
      } else {
        action = "update";
        request = axios.put(`/services/user-charts/${chartId}`, {data});
      }

      request.then(() => {
        this.loading = false;
        this.$root.$el.dispatchEvent(
            new CustomEvent("action-performed", {
              detail: {
                action,
                data: JSON.parse(JSON.stringify(this.$store.state.config)),
              },
            })
        );
      });
    },
    showLoading(status) {
      this.loading = status;
    },
    dispatchConfigUpdatedEvent: debounce(function () {
      this.$root.$el.dispatchEvent(
          new CustomEvent("chart-config-updated", {
            detail: {
              data: JSON.parse(JSON.stringify(this.$store.state.config)),
            },
          })
      );
    }, 800),
  },
};
</script>
<style lang="scss" scoped>
.header-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 3.5rem;
  padding: 0 1rem;
  background-color: #f0f0f000;
}

.header-title {
  flex: 1;
  display: flex;
  align-items: start;
  justify-content: flex-start;
}

.header-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-item-title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1rem;
}

.btn-save {
  background-color: transparent;
  color: #fff;
  text-align: center;
  border: none;
  padding: 0.25rem 0.8rem;
  border-radius: 0.25rem;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 1rem;
  z-index: 1;

  &:hover {
    color: #fff;
  }
}

.widget-title-input {
  z-index: 1;
}

.widget-input {
  width: 90%;
  border: none;
  background-color: transparent;
  text-align: center;
  margin-left: 1rem;
}

#widget_name {
  font-size: 1.4rem;
  font-weight: normal;
  color: #000;
  background-color: transparent;
  border: none;
  text-align: start;

  &:focus-visible {
    outline: none;
    border-bottom: 3px solid #000;
  }
}

.options-link {
  text-decoration: none;
  z-index: 1;
}

.options-link:hover {
  text-decoration: underline;
}

.spinner {
  position: absolute;
  top: 15px;
  right: 25px;
}

.dark-layout {
  #widget_name {
    color: #fff;

    &:focus-visible {
      border-bottom: 3px solid #fff;
    }
  }
}
</style>
