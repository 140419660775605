<template>
  <div class="legend-container">
    <ul class="legend" :style="'max-height: ' + viewportHeight + 'px;'">
      <li
        v-for="(label, index) in paginatedData"
        :key="index"
        class="legend-item"
      >
        <span
          class="legend-color"
          :style="`border-color: ${label.color}`"
        >
        <span class="legend-color-center"></span>
      </span>
        <div class="legend-text">
          {{ label.text }} <br />
          <span class="small"> {{ label.value }} </span>
        </div>
      </li>
    </ul>
    <div v-if="labels.length > pageSize" class="d-flex justify-content-center">
      <a
        href="#"
        class="btn btn-sm btn-secondary px-30 rounded-0"
        @click.prevent="prevPage()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </a>

      <a
        href="#"
        class="btn btn-sm btn-secondary px-30 rounded-0"
        @click.prevent="nextPage()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          height="16px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-right"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    labels: Array,
    colors: Array,
    values: Array,
    currentField: {
      type: Object,
      default: () => ({}),
    },
    total: String,
  },
  data() {
    return {
      pageSize: 8,
      pageNumber: 1,
      legendData: [],
      numPages: 0,
    };
  },
  mounted() {
    this.processData();
  },
  watch: {
    labels() {
      this.processData();
    },
  },
  computed: {
    paginatedData() {
      if (!this.legendData.length) return [];

      return this.legendData.slice(
        (this.pageNumber - 1) * this.pageSize,
        this.pageNumber * this.pageSize
      );
    },
    viewportHeight() {
      const heightV = this.$store.getters.viewport.height;
      return heightV - 70;
    },
  },
  methods: {
    processData() {
      this.pageNumber = 1;
      this.legendData = [];
      this.numPages = 0;

      this.numPages = Math.ceil(this.labels.length / this.pageSize);
      this.legendData = this.labels.map((val, index) => {
        const value = !this.currentField.percentage
          ? this.formatNumber(this.values[index])
          : `${this.values[index]}%`;
        return {
          text: val,
          value,
          color: this.colors[index],
        };
      });
    },
    prevPage() {
      if (this.pageNumber > 1) {
        this.pageNumber -= 1;
      }
    },
    nextPage() {
      if (this.pageNumber < this.numPages) {
        this.pageNumber += 1;
      }
    },
    formatNumber(num) {
      return new Intl.NumberFormat("en-US").format(num);
    },
  },
};
</script>

<style lang="scss" scoped>
.legend-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Ensure full width within the grid column */
}

.legend {
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  border: 5px solid #fff;
}


.legend-text {
  flex-grow: 1;
  /* Allow text to expand as needed */
  font-size: 14px;
  /* Adjust base font size */
}

.small {
  font-size: 0.8rem;
}
.dark-layout {

}
</style>
