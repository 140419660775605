export default {
  namespaced: false,
  base: {
    app: {
      loading: 'Loading',
      chartsNotFound: 'Chart not found',
      save: 'Save',
      refreshInterval: 'Update data every 10 seconds',
      noInformationAvailable: 'No information available',
      total: 'Total',
      prev: 'Previous',
      next: 'Next',
      appliedFilters: 'Applied filters (metadata)',
    },
    form: {
      name: 'Name',
      model: 'Model',
      selectModel: 'Select the model',
      field: 'Field',
      chart: 'Chart', // Gráfico
      continue: 'Continue',
      sensors: 'Sensors',
      image_heatmap: 'Image Heat Map',
      facility: 'Facility',
      camera: 'Camera'
    },
    optionsPanel: {
      fields: 'Fields',
      chart: 'Chart', //'Gráfico',
      charts: {
        bar: 'Bar',
        pie: 'Pie', // Pie/Pastel,
        map: 'Map',
        doughnut : 'Doughnut',
      },
      filters: 'Filters',
      metadata: 'Metadata',
      dates: {
        from: 'From',
        to: 'To'
      },
      metadataFilter: {
        title: 'Search for objects',
        field1: {
          label: 'Provider'
        },
        field2: {
          label: 'Type'
        },
        field3: {
          label: 'From'
        },
        field4: {
          label: 'To'
        },
        field5: {
          label: 'Object'
        },
        operators: {
          equal_to: 'equal to',
          not_equal_to: 'not equal to',
          contains: 'contains',
          not_contains: 'does not contain',
          gt: 'greater than',
          lt: 'less than',
          gte: 'greater than or equal to',
          lte: 'less than or equal to',
          is_in: 'is in',
          is_not_in: 'is not in',
        },
        lists: {
          gender: {
            female: 'Female',
            male: 'Male',
          }
        },
      },
      apply: 'Apply',
    },
    today: 'Today',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    hour: 'Hour',
    custom: 'Custom',
    installation_components: {
      following: "Following",
      previous: "Previous",
      no_options_found: "No options found",
    },
  },
};
