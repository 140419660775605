import {Loader} from "@googlemaps/js-api-loader";

export const loaded = new Promise((resolve, reject) => {
    window['ChartsGoogleMapsInit'] = resolve;
});

export default {
    loaded: false,
    load(apiKey) {
        const loader = new Loader({
            apiKey: apiKey,
            version: "beta",
            libraries: ["visualization"],
            language: "en"
        });

        loader.load().then(() => {
            window.ChartsGoogleMapsInit();
        });
    }
};

