<template>
    <div class="options-panel p-2" :class="{ show: show }">
        <div class="d-flex justify-content-end mb-3">
            <a href="#" class="float-right" @click.prevent="$emit('update:show', !show)">
                <icon-component icon="times-icon" size="20" />
            </a>
        </div>

        <div class="mb-3" v-if="modelOptions">
            <p class="lead">
                {{ $t('form.sensors') }}
            </p>
            <template v-for="(field, index) in modelOptions.fields">
                <div :key="index">
                    <v-select
                        v-if="field.type === 'select' || field.type === 'multiple'"
                        v-model="localFilters[field.value]"
                        :options="parseOptions(field.options)"
                        :clearable="true"
                        :multiple="field.type === 'multiple'"
                        :append-to-body="true"
                        :reduce="(val) => val.value"
                        :calculate-position="withPopper"
                        @input="onInput($event, field.value)"
                        class="charts-sdk-select"
                    />
                </div>
            </template>
        </div>

        <div class="mb-4" v-if="selectedField">
            <p class="lead">{{ $t('optionsPanel.chart') }}</p>
            <select
                id="chart"
                name="chart"
                class="form-control form-control-sm"
                v-model="widget.chart"
                @change="$emit('update:widget', $event.target.value)"
            >
                <option value="bar" selected v-if="selectedField.charts.includes('bar')">
                    {{ $t('optionsPanel.charts.bar') }}
                </option>
                <option value="pie" v-if="selectedField.charts.includes('pie')">
                    {{ $t('optionsPanel.charts.doughnut') }}
                </option>
                <option value="map" selected v-if="selectedField.charts.includes('map')">
                    {{ $t('optionsPanel.charts.map') }}
                </option>
            </select>
        </div>

        <div>
            <p class="lead">{{ $t('optionsPanel.filters') }}</p>
            <table class="table table-bordered" v-if="modelOptions">
                <template v-for="(field, index) in modelOptions.fields">
                    <tr v-if="modelOptions.filters.includes(field.value) && field.visible_in !== 'fields'" :key="index">
                        <td style="width: 33.3%">{{ field.text }}</td>
                        <td>
                            <span class="d-block text-center lead">=</span>
                        </td>
                        <td>
                            <v-select
                                v-if="field.type === 'select' || field.type === 'multiple'"
                                v-model="localFilters[field.value]"
                                :options="parseOptions(field.options)"
                                :clearable="true"
                                :multiple="field.type === 'multiple'"
                                :append-to-body="true"
                                :reduce="(val) => val.value"
                                :calculate-position="withPopper"
                                @input="onInput($event, field.value)"
                                class="charts-sdk-select"
                            />
                            <template v-if="field.type === 'date'">
                                <div class="form-row">
                                    <div class="d-flex align-items-center justify-content-center p-0 mr-2">
                                        {{ $t('optionsPanel.dates.from') }}
                                    </div>
                                    <div class="col">
                                        <input
                                            type="datetime-local"
                                            class="form-control form-control-sm d-inline"
                                            ref="date_from"
                                            @change="onDateChange"
                                        />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center p-0 mx-2">
                                        {{ $t('optionsPanel.dates.to') }}
                                    </div>
                                    <div class="col">
                                        <input
                                            type="datetime-local"
                                            class="form-control form-control-sm"
                                            ref="date_to"
                                            @change="onDateChange"
                                        />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center p-0 ml-2">
                                        <a href="#" class="text-danger" @click.prevent="clearDateFilter">
                                            <icon-component icon="trash-icon" size="15" color="#dc3545" />
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </td>
                    </tr>
                </template>
                <detection-events-filter
                    v-if="modelOptions.value === 'DetectionEvent'"
                    :model-data="modelOptions"
                    :value="filters"
                    @update="onFiltersUpdate"
                />
            </table>
        </div>
    </div>
</template>

<script>
import IconComponent from '../icons/icon-component.vue';
import { createPopper } from '@popperjs/core';
import DetectionEventsFilter from './DetectionEventsFilter/DetectionEventsFilter.vue';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        widget: {
            type: Object,
        },
        fields: {
            type: Array,
            default: [],
        },
        modelOptions: {
            type: Object,
        },
        filters: {
            type: Object,
        },
        selectedChartValue: {
            type: Object,
        },
    },
    data() {
        return {
            localFilters: {},
        };
    },
    mounted() {},
    components: {
        IconComponent,
        DetectionEventsFilter,
    },
    watch: {
        filters(val) {
            if (JSON.stringify(val) === '{}') {
                this.localFilters = {};
                this.$refs.date_from[0].value = null;
                this.$refs.date_to[0].value = null;
                // if (field.type !== 'date') {
                //     this.localFilters = {};
                // } else {
                //     this.$refs.date_from[0].value = null;
                //     this.$refs.date_to[0].value = null;
                // }
            }
        },
        selectedChartValue(val) {
            this.filters[val.field] = [val.value];
            this.$emit('update:filters', this.filters);
            this.$emit('apply-filters');
            this.$nextTick(() => {
                this.localFilters[val.field] =
                    val.type === 'multiple'
                        ? [{ label: val.label, value: val.value }]
                        : { label: val.label, value: val.value };
            });
        },
        modelOptions() {
            this.localFilters = JSON.parse(JSON.stringify(this.filters));

            this.$nextTick(() => {
                if (this.filters.from && this.filters.to) {
                    this.$refs.date_from[0].value = this.filters.from;
                    this.$refs.date_to[0].value = this.filters.to;
                }
            });
        },
    },
    computed: {
        selectedField() {
            if (!this.widget.field) return null;

            const field = this.fields.find((field) => field.value === this.widget.field);

            return field;
        },
    },
    methods: {
        parseOptions(options) {
            return Object.entries(options).map((obj) => ({ label: obj[1], value: obj[0] }));
        },
        setFilterOptions(filter, options) {
            let opts = [];

            if (options) {
                opts = Object.keys(options).map((k) => ({ value: options[k], id: k, filter }));
            }

            return opts;
        },
        onFieldChange(field) {
            this.$store.dispatch('setConfig', { field });
            // if (this.selectedField.charts.includes('map')) {
            //     this.widget.chart = 'map';
            //     this.$emit('update:widget', 'map');
            // }
        },
        onInput(values, filter) {
            let data = null;

            if (!values || (Array.isArray(values) && !values.length)) {
                delete this.filters[filter];
            } else {
                this.$nextTick(() => {
                    data = Array.isArray(values) ? values : [values];

                    if (data && data.length) {
                        this.filters[filter] = data;
                    }
                });
            }

            this.$nextTick(() => {
                this.$emit('update:filters', this.filters);
                this.$emit('apply-filters');
            });
        },
        onDateChange() {
            const from = this.$refs.date_from[0].value;
            const to = this.$refs.date_to[0].value;

            this.filters['from'] = from;
            this.filters['to'] = to;

            this.$emit('update:filters', this.filters);
            this.$emit('apply-filters');
        },
        clearDateFilter() {
            this.$refs.date_from[0].value = null;
            this.$refs.date_to[0].value = null;

            this.filters['from'] = null;
            this.filters['to'] = null;

            this.$emit('update:filters', this.filters);
            this.$emit('apply-filters');
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: 'top',
            });
            return () => popper.destroy();
        },
        onFiltersUpdate(data) {
            this.$nextTick(() => {
                this.$emit('update:filters', data);
                this.$emit('apply-filters');
            });
        },
    },
};
</script>

<style>
.charts-sdk-select .vs__deselect {
    fill: #e5e5e5;
    margin-left: 5px;
}

.charts-sdk-select .vs__selected {
    color: #fff;
    background-color: #1b3d5e !important;
}
</style>
