import moment from "moment";

export default {
    buildDateRangeFromWildcard(wildcard = 'month') {
        let start = null;
        let end = null;
        let format = 'YYYY-MM-DD HH:mm:ss'
        let fixedWildCard = wildcard;

        if (wildcard === 'today') {
            fixedWildCard = 'day';
        } else if (wildcard === 'week') {
            fixedWildCard = 'isoWeek';
        }

        start = moment().startOf(fixedWildCard).format(format);
        end = moment().endOf(fixedWildCard).format(format);

        return {start, end};
    },
}