export default {
  namespaced: false,
  base: {
    app: {
      loading: 'Cargando',
      chartsNotFound: 'Gráfico no encontrado',
      save: 'Guardar',
      refreshInterval: 'Actualizar información cada 10 segs.',
      noInformationAvailable: 'No hay información disponible',
      total: 'Total',
      prev: 'Anterior',
      next: 'Siguiente',
      appliedFilters: 'Filtros aplicados (metadados):',
    },
    form: {
      name: 'Nombre',
      model: 'Modelo',
      selectModel: 'Selecciona un modelo',
      field: 'Campo',
      chart: 'Gráfico',
      continue: 'Continuar',
      sensors: 'Sensores',
      facility: 'Instalación',
      camera: 'Cámara'
    },
    optionsPanel: {
      fields: 'Campos',
      chart: 'Gráfico',
      charts: {
        bar: 'Barras',
        pie: 'Pie/Pastel',
        map: 'Mapa',
        doughnut: 'Dona',
        image_heatmap: 'Imagen con mapa de calor'
      },
      filters: 'Filtros',
      metadata: 'Metadatos',
      dates: {
        from: 'de',
        to: 'a'
      },
      metadataFilter: {
        title: 'Búsqueda por objetos',
        field1: {
          label: 'Proveedor'
        },
        field2: {
          label: 'Tipo'
        },
        field3: {
          label: 'Desde'
        },
        field4: {
          label: 'Hasta'
        },
        field5: {
          label: 'Objeto'
        },
        operators: {
          equal_to: 'igual a',
          not_equal_to: 'diferente de',
          contains: 'contiene',
          not_contains: 'no contiene',
          gt: 'mayor que',
          lt: 'menor que',
          gte: 'mayor o igual que',
          lte: 'menor o igual que',
          is_in: 'está en',
          is_not_in: 'no está en',
        },
        lists: {
          gender: {
            female: 'Femenino',
            male: 'Masculino',
          }
        },
      },
      apply: 'Aplicar',
    },
    today: 'Hoy',
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
    year: 'Año',
    hour: 'Hora',
    custom: 'Personalizado',
    installation_components: {
      following: "Siguiente",
      previous: "Anterior",
      no_options_found: "No se encontraron, opciones",
    },
  }
};
