<template>
    <div class="options-panel p-2" :class="{ show: show }">
        <!-- Panel Header -->
        <div class="d-flex justify-content-end mb-3">
            <a href="#" class="float-right" @click.prevent="$emit('update:show', !show)">
                <icon-component icon="times-icon" size="20" />
            </a>
        </div>

        <!-- Debug Section -->
        <!-- <div class="debug-section">
            <h5>Debug Information</h5>
            <pre>Filters: {{ JSON.stringify(filters, null, 2) }}</pre>
            <pre>localFilters: {{ JSON.stringify(localFilters, null, 2) }}</pre>
            <pre>modelOptions.fields: {{ JSON.stringify(modelOptions.fields, null, 2) }}</pre>
        </div> -->

        <!-- Field Selector -->
        <div class="mb-3">
            <p class="lead">{{ $t("optionsPanel.fields") }}</p>
            <select id="field" class="form-control form-control-sm form-control-input"
                @change="onFieldChange($event.target.value)">
                <option disabled>{{ $t("optionsPanel.fields") }}</option>
                <template v-for="(field, index) in fields">
                    <option v-if="field.visible_in !== 'filters'" :key="index" :value="field.value"
                        :selected="widget.field === field.value">
                        {{ field.text }}
                    </option>
                </template>
            </select>
        </div>

        <!-- Chart Type Selector -->
        <div class="mb-4" v-if="selectedField">
            <p class="lead">{{ $t("optionsPanel.chart") }}</p>
            <select id="chart" name="chart" class="form-control form-control-sm form-control-input"
                v-model="widget.chart" @change="$emit('update:widget', $event.target.value)">
                <option value="bar" selected v-if="selectedField.charts.includes('bar')">
                    {{ $t("optionsPanel.charts.bar") }}
                </option>
                <option value="pie" v-if="selectedField.charts.includes('pie')">
                    {{ $t("optionsPanel.charts.pie") }}
                </option>
                <option value="map" selected v-if="selectedField.charts.includes('map')">
                    {{ $t("optionsPanel.charts.map") }}
                </option>
                <option value="image_heat_map" v-if="selectedField.charts.includes('image_heat_map')">{{ $t("optionsPanel.charts.image_heat_map") }}</option>
            </select>
        </div>

        <!-- Filter Section -->
        <div>
            <p class="lead">{{ $t("optionsPanel.filters") }}</p>
            <table class="table table-bordered" v-if="modelOptions">
                <template v-for="(field, index) in modelOptions.fields">
                    <tr v-if="modelOptions.filters.includes(field.value) && field.visible_in !== 'fields'" :key="index">
                        <td style="width: 33.3%">{{ field.text }}</td>
                        <td>
                            <span class="d-block text-center lead">=</span>
                        </td>
                        <td>
                            <v-select 
                                v-if="field.type === 'select' || field.type === 'multiple'"
                                v-model="localFilters[field.value]" 
                                :options="parseOptions(field.options)"
                                :clearable="true" 
                                :multiple="field.value !== 'id_camera_proxy'" 
                                :append-to-body="true"
                                :reduce="(val) => val.value" 
                                :calculate-position="withPopper"
                                @input="onInput($event, field.value)" 
                                class="charts-sdk-select" 
                            />
                            <template v-if="field.type === 'date'">
                                <div class="d-flex justify-content-around">
                                    <div class="d-flex flex-column">
                                        <!-- from -->
                                        <div class="d-flex mb-3">
                                            <div class="d-flex align-items-center justify-content-center p-0">
                                                {{ $t("optionsPanel.dates.from") }}
                                            </div>
                                            <div class="col custom-datetime-icon">
                                                <input type="datetime-local" class="form-control form-control-sm d-inline datetime-local" ref="date_from" @change="onDateChange" />
                                            </div>
                                        </div>
                                        <!-- to -->
                                        <div class="d-flex">
                                            <div class="d-flex align-items-center justify-content-center p-0 mx-2">
                                                {{ $t("optionsPanel.dates.to") }}
                                            </div>
                                            <div class="col custom-datetime-icon">
                                                <input type="datetime-local" class="form-control form-control-sm custom-datetime-local" ref="date_to" @change="onDateChange" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- clear -->
                                    <div class="d-flex align-items-center p-0">
                                        <a href="#" class="text-danger" @click.prevent="clearDateFilter">
                                            <icon-component icon="trash-icon" size="12" color="#dc3545" />
                                        </a>
                                    </div>
                                </div>
                            </template>
                        </td>
                    </tr>
                </template>
                <detection-events-filter v-if="modelOptions.value === 'DetectionEvent'" :model-data="modelOptions" :value="filters" @update="onFiltersUpdate" />
            </table>
        </div>
    </div>
</template>

<script>
import IconComponent from "../icons/icon-component.vue";
import { createPopper } from "@popperjs/core";
import DetectionEventsFilter from "./DetectionEventsFilter/DetectionEventsFilter.vue";

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        widget: {
            type: Object,
        },
        fields: {
            type: Array,
            default: [],
        },
        modelOptions: {
            type: Object,
        },
        filters: {
            type: Object,
        },
        selectedChartValue: {
            type: Object,
        },
        keepFilters: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            localFilters: {},
        };
    },
    mounted() { 
        this.initializeLocalFilters();
    },
    components: {
        IconComponent,
        DetectionEventsFilter,
    },
    watch: {
        filters(val) {
            this.syncLocalFilters();
        },
        selectedChartValue(val) {
            this.filters[val.field] = [val.value];
            this.$emit("update:filters", this.filters);
            this.$emit("apply-filters");
            this.$nextTick(() => {
                this.localFilters[val.field] =
                    val.type === "multiple"
                        ? [{ label: val.label, value: val.value }]
                        : { label: val.label, value: val.value };
            });
        },
        modelOptions(val) {
            //console.log("Watch - modelOptions - this.modelOptions", this.modelOptions)
            //console.log("Watch - modelOptions - this.filters", this.filters)
            this.syncLocalFilters();
        },
    },
    methods: {
        parseOptions(options) {
            return Object.entries(options).map((obj) => ({
                label: obj[1],
                value: obj[0],
            }));
        },
        onFieldChange(field) {
            this.$store.dispatch("setConfig", { field });
        },
        onInput(values, filter) {
            this.filters[filter] = Array.isArray(values) ? values : [values];
            this.$emit("update:filters", this.filters);
            this.$emit("apply-filters");
        },
        onDateChange() {
            const from = this.$refs.date_from[0].value;
            const to = this.$refs.date_to[0].value;
            this.filters["from"] = from;
            this.filters["to"] = to;
            this.$emit("update:filters", this.filters);
            this.$emit("apply-filters");
        },
        clearDateFilter() {
            this.$refs.date_from[0].value = null;
            this.$refs.date_to[0].value = null;
            this.filters["from"] = null;
            this.filters["to"] = null;
            this.$emit("update:filters", this.filters);
            this.$emit("apply-filters");
        },
        withPopper(dropdownList, component, { width }) {
            dropdownList.style.width = width;
            const popper = createPopper(component.$refs.toggle, dropdownList, {
                placement: "top",
            });
            return () => popper.destroy();
        },
        onFiltersUpdate(data) {
            this.$emit("update:filters", data);
            this.$emit("apply-filters");
        },
        initializeLocalFilters() {
            // if (this.widget.camera) {
            //     this.filters.id_camera_proxy = [String(this.widget.camera.value)];
            //     this.$emit("update:filters", this.filters);
            // }
        },
        syncLocalFilters() {
            this.localFilters = JSON.parse(JSON.stringify(this.filters));
            this.$nextTick(() => {
                if (this.filters.from && this.filters.to) {
                    this.$refs.date_from[0].value = this.filters.from;
                    this.$refs.date_to[0].value = this.filters.to;
                }
            });
        },
    },
};
</script>

<style scoped>
.charts-sdk-select .vs__deselect {
    fill: #e5e5e5;
    margin-left: 5px;
}

.charts-sdk-select .vs__selected {
    color: #fff !important;
    background-color: #1b3d5e !important;
}

.dark-layout .custom-datetime-icon input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    filter: invert(100%);
}
</style>
