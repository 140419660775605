export default {
  namespaced: false,
  base: {
    app: {
      loading: 'Carregando',
      chartsNotFound: 'Gráfico não encontrado',
      save: 'Salvar',
      refreshInterval: 'Atualize as informações a cada 10 segundos.',
      noInformationAvailable: 'Nenhuma informação disponível',
      total: 'Total',
      prev: 'Anterior',
      next: 'Próximo',
      appliedFilters: 'Filtros aplicados (metadados)',
    },
    form: {
      name: 'Nome',
      model: 'Modelo',
      selectModel: 'Selecione o modelo',
      field: 'Campo',
      chart: 'Gráfico',
      continue: 'Continuar',
      sensors: 'Sensores',
      facility: 'Instalação',
      camera: 'Câmera'
    },
    optionsPanel: {
      fields: 'Campos',
      chart: 'Gráfico',
      charts: {
        bar: 'Barras',
        pie: 'Pizza',
        map: 'Mapa',
        doughnut : 'Rosca',
        image_heatmap: 'Imagem com mapa de calor'
      },
      filters: 'Filtros',
      metadata: 'Metadatos',
      dates: {
        from: 'de',
        to: 'para'
      },
      metadataFilter: {
        title: 'Pesquisar por objetos',
        field1: {
          label: 'Fornecedor'
        },
        field2: {
          label: 'Tipo'
        },
        field3: {
          label: 'De'
        },
        field4: {
          label: 'Para'
        },
        field5: {
          label: 'Objeto'
        },
        operators: {
          equal_to: 'igual a',
          not_equal_to: 'diferente de',
          contains: 'contém',
          not_contains: 'não contém',
          gt: 'maior que',
          lt: 'menor que',
          gte: 'maior ou igual a',
          lte: 'menor ou igual a',
          is_in: 'está em',
          is_not_in: 'não está em',
        },
        lists: {
          gender: {
            female: 'Fêmea',
            male: 'Masculino',
          }
        },
      },
      apply: 'Aplicar',
    },
    today: 'Hoje',
    day: 'Dia',
    week: 'Semana',
    month: 'Mês',
    year: 'Ano',
    hour: 'Hora',
    custom: 'Personalizado',
    installation_components: {
      following: "Seguindo",
      previous: "Anterior",
      no_options_found: "Nenhuma opção encontrada",
    },
  }
};
